/** Different tabs positions, which were removed from bootstrap */

.tabs-below,
.tabs-right,
.tabs-left {
    .nav-tabs {
        border-bottom: 0;
    }
}

.tabs-right,
.tabs-left {
    .nav-tabs {
        min-width: 100px;
    }

    .tab-content {
        width: calc(100% - 100px);
        overflow-y: auto;
    }
}

.tabs-right .tab-content {
    margin-right: 100px;
}

.tabs-left .tab-content {
    margin-left: 100px;
}

.tab-content>.tab-pane,
.pill-content>.pill-pane {
    display: none;
}

.tab-content>.active,
.pill-content>.active {
    display: block;
}

.tabs-below {
    >.nav-tabs {
        >li {
            margin-top: -1px;
            margin-bottom: 0;
        }
    }
}

.tabs-left,
.tabs-right {
    height: 100%;

    >.nav-tabs>li {
        float: none;
        margin-bottom: 0;

        >a {
            min-width: 74px;
            margin-right: 0;
            margin-bottom: 3px;
        }
    }
}

.tabs-left>.nav-tabs {
    float: left;
    border-bottom-left-radius: 5px;

    >li>a {
        margin-right: -1px;
    }
}

.tabs-right>.nav.nav-tabs {
    float: right;
    border-top-left-radius: 0;
    border-bottom-right-radius: 5px;

    &>li:first-of-type a {
        border-top-left-radius: 0;
    }
}

/** /Different tabs positions, which were removed from bootstrap */

.nav-tabs>li.with-dropdown {
    >a {
        padding: 0;
    }

    .dropdown-toggle {
        padding: 10px 15px;
        display: inline-block;
        cursor: pointer;
    }
}

.tab-content {
    padding: 15px 15px 5px 15px;
    background: transparent;
    color: $default-text;

    .tab-pane p {
        color: $default-text;
    }

    @media screen and (max-width: $resM) {
        padding: 15px 1px 5px 1px;
    }

    .mat-mdc-row:nth-child(even) {
        background-color: #ffffff;
    }

    .mat-mdc-row:nth-child(odd) {
        background-color: #eeeeee;
    }

    .mat-mdc-row:hover {
        cursor: pointer;
        border-color: cadetblue;
    }

    .mat-mdc-header-row {
        padding: 0px;

        .mat-mdc-header-cell {
            span {
                font-size: 14px;
            }
        }
    }

    .mat-mdc-table {
        background: transparent;

        .mat-mdc-row {
            background-color: #fff !important;
            padding: 8px 10px;
            border-radius: 5px;
            margin: 10px 0;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

            .mat-mdc-cell:nth-child(3) {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    mat-paginator {
        background: transparent;
    }
}

.nav.nav-tabs {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: 1px solid transparent;
    background-color: $primary-light;

    a {
        color: $label-text;

        &:hover {
            color: $label-text;
        }
    }

    &>li>a {
        margin-right: 0;
        margin-bottom: 0;
        border-radius: 0;
        border: none;

        &:hover {
            border: none;
            background-color: $primary-light;
        }
    }

    &>li.active>a {
        color: $label-text;
        background-color: $primary;
    }

    >li:first-of-type a {
        border-top-left-radius: 5px;
    }

    .dropdown-menu>li>a {
        color: $dropdown-text;

        &:hover {
            color: $dropdown-text;
        }
    }
}

.blur {
    .nav.nav-tabs {
        background-color: rgba(0, 0, 0, 0.2);

        a {
            color: $default-text;

            &:hover {
                color: $default-text;
            }
        }

        &>li>a:hover {
            background-color: rgba(0, 0, 0, 0.2);
        }

        &>li.active>a {
            color: $default-text;
            background-color: rgba(0, 0, 0, 0.25);
        }
    }
}

.nav .open>a,
.nav .open>a:hover,
.nav .open>a:focus {
    background-color: transparent;
}

.nav-tabs>li.active>a,
.nav-tabs>li.active>a:hover,
.nav-tabs>li.active>a:focus {
    border: none;
}

.accordion-panel {
    .panel-heading {
        border-radius: 3px;
    }

    &.panel-open .panel-heading {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    &:not(.panel-open) .panel-heading {
        transition-delay: .3s;
    }

    >.panel-heading+.panel-collapse>.panel-body {
        border-top: none;
    }
}

.accordion-panel .panel-heading {
    padding: 0;

    .accordion-toggle {
        display: inline-block;
        width: 100%;
        padding: 14px 22px;
    }
}