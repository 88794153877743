.app-card-wrapper {
    .card-2 {
        position: relative;
        box-shadow: 0 5px 30px 0 rgba(102, 148, 155, 0.4);
        border-radius: 10px;
        padding: 13px 0 20px;
        background: #fff;
        margin: 20px 0 10px;

        .top_section {
            h2 {
                text-align: center;

                .image_span img {
                    width: 20px;

                    @media screen and (max-width: 1440px) {
                        width: 28px;
                    }
                }

                .text_span {
                    font-size: 13px;
                    font-weight: 400;

                    @media screen and (max-width: 1440px) {
                        font-size: 15px;
                    }
                }
            }
        }

        .card-meta {
            max-height: 0;
            overflow: hidden;
            color: #666;
            font-size: .78em;
            position: absolute;
            bottom: 5%;
            padding: 0 5%;
            -webkit-transition-property: max-height;
            /* Safari */
            -webkit-transition-duration: .4s;
            /* Safari */
            transition-property: max-height;
            transition-duration: .4s;
            -webkit-animation-timing-function: ease-in-out;
            /* Safari  */
            animation-timing-function: ease-in-out;
            width: 100%;
            color: #fff;
            background: #003060;
            position: absolute;
            bottom: 0px;
            left: 0;
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
            text-align: center;

            i {
                color: #02203f;
            }
        }

        .application_btn {
            button {
                transition-duration: .4s;
                font-size: 13px;
                margin-top: 5px;
                line-height: 30px;
            }
        }

        &:hover {
            .card-meta {
                max-height: 3em;
            }
        }

        .new-tag {
            background: linear-gradient(135deg, #ff3837 0%, #ff8008 100%);
            box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
            position: absolute;
            top: 0;
            right: 0;
            padding: 1px 7px;
            font-size: 10px;
            color: #fff;
            border-radius: 0 10px;
            font-weight: bold;
            letter-spacing: .5px;
        }
    }
}

.snb-form-card {
    text-align: center;
    border-radius: 15px;

    ._top_sec {
        display: inline-block;

        &:before {
            content: '';
            width: 280px;
            height: 30px;
            background-image: url('/../../../assets/img/card-form-bg.png');
            position: absolute;
            background-size: 100% auto;
            background-repeat: no-repeat;
            bottom: -30px;
            left: -80px;
        }

        position: relative;
        margin-bottom: 15px;

        ._icon {
            position: absolute;
            top: 10px;

            svg {
                max-height: 54px;
                max-width: 54px;
                height: 54px;
                fill: $snb-form-title-icon-color;
            }

            i {
                font-size: 40px;
                line-height: 50px;
                color: $snb-form-title-icon-color;
            }
        }

        ._title {
            line-height: 74px;
            margin-left: 54px;
            font-size: 20px;
        }
    }

    ._middle_sec {
        margin-bottom: 10px;
        font-size: 15px;
        text-align: center;
        line-height: 25px;
        max-height: 61px;
        min-height: 47px;
        overflow: hidden;
        display: block;
        padding: 0 21px;

        ._desk {
            padding: 0 15px;
        }
    }

    ._bottom_sec {
        margin-bottom: 15px;
        display: inline-block;
        text-align: center;

        _button {
            button {
                border-radius: 30px !important;
            }
        }
    }
}

.snb_content_section {
    .form_left_block {
        float: left;
    }
    .form_right_block {
        float: right;

        @media screen and (max-width: $resM) {
            margin-top: 30px;
            padding: 10px;
        }
    }

    .roc_need_more_info_text {
        font-weight: 600;
        font-size: 14px;
        padding-bottom: 19px;
        display: block;
        margin: 0px 0px 5px 15px;
    }

    .show-next-route-msg {
        background: #2E7D32;
        min-height: 45px;
        max-height: 75px;
        text-align: center;
        margin: 0px;
        top: -15px;
        margin-bottom: 20px;
        box-shadow: 0 0 20px rgba(145, 210, 220, 0.5);
        color: #fff;

        p:first-child {
            font-weight: bold;
            font-size: 18px;
        }

        button {
            line-height: 25px !important;
            padding: 0 10px !important;
            margin: 0 5px !important;
        }

        a {
            font-weight: bold;
            text-decoration: underline;
        }
    }
}

// .mat-mdc-form-field {
//     .mdc-text-field {
//         padding: 0;

//         &--filled:not(.mdc-text-field--disabled),
//         &--filled.mdc-text-field--disabled {
//             background-color: transparent;
//         }
//     }

//     // .mat-form-field-disabled .mat-form-field-underline {
//     //     background-position: 0;
//     //     background-color: transparent;
//     // }
//     // .mat-form-field-disabled .mat-form-field-underline {
//     //     background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0, rgba(0, 0, 0, 0.42) 33%, transparent 0);
//     //     background-size: 4px 1px;
//     //     background-repeat: repeat-x;
//     // }
// }

.snb_form_entries {
    margin-bottom: 15px;

    .col-center {
        margin: 0 auto;
        max-width: 80%;
    }

    .col-md-6 .mat-form-field {
        height: 84px;
    }

    .mat-select-value {
        overflow: visible;
    }

    .mat-form-field-disabled,
    .mat-select-disabled .mat-select-value {
        cursor: not-allowed;
        color: rgba(0, 0, 0, 0.38);

        .mat-form-field-infix i {
            color: rgba(0, 0, 0, 0.38);
        }
    }

    // .mat-mdc-form-field {
    //     span.calc-value {
    //         position: absolute;
    //         top: 15px;
    //         right: 5px;
    //         background: #80808082;
    //         padding: 2px;
    //     }

    //     width: 98%;

        .mat-mdc-text-field-wrapper
        .mat-input-element,
        .mat-select {
            background: #fbfdfd;
            line-height: 40px;
            border-radius: 5px;
            padding-left: 0px;
            border: 1px solid #f0f3f4;
            font-size: 16px;
        }

        
    //     .mat-select-disabled {
    //         cursor: not-allowed;
    //     }

        
    //     .mdc-floating-label,
    //     .mat-form-field-label {
    //         top: 1.75125em;
    //         padding-left: 0px;
    //         font-size: 16px;
    //         color: #282828;
    //         max-width: 200%;
    //     }

    //     .material-icons,
    //     .fa {
    //         //position: absolute;
    //         //line-height: 40px;
    //         border-right: 2px solid white;
    //         padding: 0 5px;
    //         //top: 5px;
    //         color: $snb-input-icon-color;
    //         width: 36px;
    //         text-align: center;

    //         &.info {
    //             font-size: 16px;
    //             right: 0;
    //             top: -6px;
    //             line-height: 0;
    //             width: 20px;
    //             color: #bdbdbd;
    //         }
    //     }

    //     .fa {
    //         font-size: 18px;
    //     }
    // }

    // .snb-form-field-without-icon {
    //     .mat-form-field-label {
    //         padding-left: 10px !important;
    //         top: 1.9em !important;
    //     }
    // }

    .mat-form-field-subscript-wrapper {
        margin-top: -0.2167em;
        text-align: right;
    }

    .mat-placeholder-required {
        position: relative;
        top: -4px;
        left: -2px;
    }

    .mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label,
    .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
        transform: translateY(-1.75125em) translateX(-30px) scale(0.75) perspective(100px) translateZ(0.001px);
        -ms-transform: translateY(-1.75125em) scale(0.75) translateX(-30px);
        width: 133.33333%;
    }

    .mat-focused {
        .mat-input-element {
            box-shadow: 0 1px 3px rgba(68, 138, 255, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
            transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
            background: #fff;
            border: 1px solid transparent;
        }

        // .material-icons {
        //     border-right: 2px solid #fbfdfd;
        // }
    }

    .mat-form-field-invalid:not(.mat-focused) .mat-form-field-underline .mat-form-field-ripple {
        visibility: hidden;
        opacity: 0;
    }

    .mat-form-field-underline {
        visibility: hidden;
        opacity: 0;
    }

    .snb_form_sub_section {
        padding: 10px 0;
        background-color: #fbfdfd;
        margin: 0 15px 15px 15px;
        border-radius: 5px;

        .col-md-4 {
            padding: 0 5px;
        }

        .mat-form-field {
            .mat-input-element {
                border: 0px;
            }
        }

        .mat-form-field-underline {
            visibility: visible;
            opacity: 0.7;
            background-color: #c0cdd8;
        }

        .mat-form-field-subscript-wrapper {
            margin-top: 0.3167em;
            text-align: right;
        }
    }

    .snb_form_terms {
        margin-bottom: 15px;
    }

    .snb_form_actions {
        margin-bottom: 15px;

        .mat-mdc-raised-button {
            padding: 5px 25px;
            border-radius: 30px;
            height: 46px;
            font-size: 16px;
            margin: 10px;
            text-transform: uppercase;
            overflow: hidden;
            cursor: pointer;

            i {
                font-size: 20px;
                margin-right: 5px;
            }

            &.snb_btn_submit {
                background: $snb-form-btn-submit;
                color: #fff;

                &:hover {
                    background: $snb-form-btn-hover
                }
            }

            &.snb_btn_submit[disabled] {
                background-color: rgba(0, 0, 0, 0.12) !important;
                cursor: not-allowed;

                &:hover {
                    background-color: rgba(0, 0, 0, 0.12) !important;
                    cursor: not-allowed;
                }
            }

            &.snb_btn_reset {
                i {
                    color: #f44336;
                }
            }

            &.snb_btn_reject {
                i {
                    color: #f44336;
                    transform: rotate(-180deg);
                    -webkit-transform: rotate(-180deg);
                    -moz-transform: rotate(-180deg);
                }
            }

            &.snb_btn_approve {
                i {
                    color: #80d58c;
                }
            }

            @media screen and (max-width: 768px) {
                padding: 0px 10px;
                border-radius: 30px;
                font-size: 14px;
                margin: 5px;
            }
        }

        mat-radio-group {
            mat-radio-button {
                padding-right: 50px;
            }
        }
    }

    .snb_form_approvals {
        margin-bottom: 15px;

        .form_verify_msg {
            font-size: 24px;
            font-weight: 300;
            color: #888888;
            line-height: 40px;
            margin-bottom: 15px;

            .msg_txt {
                display: inline-block;
                font-weight: 700;
                color: #464646;
                background: #f0f0f0;
                padding: 0px 10px;
                border-radius: 5px;
            }

            @media screen and (max-width: 768px) {
                font-size: 15px;
            }
        }
    }

    .snb_form_terms {
        >span {
            position: relative;
            top: -2px;
        }
    }
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: $snb-terms-icon !important;
}

.mat-mdc-form-field {
    span.calc-value {
        position: absolute;
        top: 5px;
        right: 5px;
        background: #80808082;
        padding: 2px;
    }

    width: 100%;
}

// .mdc-text-field--focused {
//     box-shadow: 0 1px 3px rgba(68, 138, 255, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
//     transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
//     background: #fff;
//     border: 1px solid transparent;
// }

.mat-mdc-text-field-wrapper {
    background: #fbfdfd;
    //line-height: 40px;
    border-radius: 5px;
    padding-left: 0px;
    // border: 1px solid #f0f3f4;
    font-size: 16px;
}

.sub_label {
    margin-bottom: 5px;
    padding: 10px 0px;
    line-height: 30px;
    position: relative;

    h5 {
        background: #f8f8f8;
        /* margin-right: 55px; */
        line-height: 30px;
        padding-left: 15px;
        border-radius: 5px;
        border-left: 8px solid #9E9E9E;
    }

    i {
        position: absolute;
        top: 8px;
        right: 12px;
        font-size: 34px;
    }
}

.print-form-icon {
    display: inline-block;
    cursor: pointer;
    background: #fff;
    color: $snb-form-sub-link-color;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    margin: 0 0 0 10px;
    box-shadow: 0 0 20px rgba(145, 210, 220, 0.5);
    transition: all 0.3s ease-in-out;

    &:hover {
        background: $snb-form-sub-link-color;
        color: #fff;
    }

    i {
        line-height: 40px;
    }
}

.empty-row {
    height: 50px;
    text-align: center;
    padding: .5rem 1.2rem;
    vertical-align: top;
    border-top: 0;
    padding-top: 15px !important;
}

.snb_form_top_actions {
    background: #f8f8f8;
    padding: 10px 24px;
    text-align: right;

    &.left-panel-top {
        margin: -24px 0 24px -24px;

        @media screen and (max-width: $resM) {
            margin: -24px 0 15px 0;
        }
    }

    &.right-panel-top {
        margin: -24px -24px 0px 0px;

        @media screen and (max-width: $resM) {
            margin: -24px 0 15px 0;
        }
    }

    a {
        display: inline-block;
        cursor: pointer;
        background: #fff;
        color: $snb-form-sub-link-color !important;
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        margin: 0 0 0 10px;
        box-shadow: 0 0 20px rgba(145, 210, 220, 0.5);
        transition: all 0.3s ease-in-out;

        &:hover {
            background: $snb-form-sub-link-color;
            color: #fff;
        }

        i {
            line-height: 40px;
        }
    }
}

.form_field {
    border: 1px solid #e7e7e7;
    padding: 25px 15px 10px 15px;
    position: relative;
    margin-bottom: 30px;
    transition: 0.3s;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);

    &:hover {
        box-shadow: 0 1px 3px rgba(145, 210, 220, 0.12), 0 1px 2px rgba(145, 210, 220, 0.24);
    }

    %ribbon {
        position: absolute;
        top: -1px;
        padding: 4px 10px;
        left: -1px;
        color: #FFF;
        border-radius: 0 0 2px 2px;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0.3px;

        &:after {
            content: '';
            position: absolute;
            right: -22px;
            top: 0;
            width: 0;
            height: 0;
            border-left: 0px solid transparent;
            border-right: 23px solid transparent;
            clear: both;
        }

    }

    .title-ribbon {
        @extend %ribbon;
        background: linear-gradient(90deg, #0055b2, #007aff);

        &:after {
            border-top: 24px solid #2979ff;
        }

        span {
            background: #efefef;
            color: #555;
            padding: 1px 10px;
            border-radius: 30px;
        }
    }

    .red-ribbon {
        @extend %ribbon;
        background: linear-gradient(90deg, #f77000, #f7bb00);

        &:after {
            border-top: 24px solid #f7bb00;
        }
    }

    .green-ribbon {
        @extend %ribbon;
        background: linear-gradient(90deg, #77ab59, #c9df8a);

        &:after {
            border-top: 24px solid #c9df8a;
        }
    }

    .sub_box {
        margin-top: 10px;

        span {
            font-weight: bold;
        }
    }
}

.full-screen-form {
    .snb_form_top_actions {
        &.left-panel-top {
            margin: -24px -24px 24px -24px !important;
        }

        &.right-panel-top {
            margin: -24px -24px 0px -24px !important;
        }
    }
}

.snb_content_section .hide-col {
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
    width: 0;
    overflow: hidden;
    display: inline-block;
    opacity: 0;
    height: 0;

    .roc_need_more_info_text {
        font-weight: 600;
        font-size: 14px;
        padding-bottom: 19px;
        display: block;
        margin: 0px 0px 5px 15px;
    }
}

.snb_form_preview_content {
    background-color: #f8f8f8;
    padding: 20px 15px;
    box-shadow: inset 0px 0px 20px rgba(0, 0, 0, 0.05);
    min-height: 400px;
    max-height: 500px;
    overflow-y: scroll;
    user-select: none;
    overflow-x: hidden;

    .form_top_block {
        margin-top: 40px;
    }

    .pso-number-block {
        position: absolute;
        top: 45px;
        z-index: 100;
        padding: 5px;
        right: 30px;
        color: green;
        background-color: #f1f7f9;
        font-weight: bold;
        border-radius: 10px;
    }

    .form_name_stick {
        position: absolute;
        left: 0;
        top: 10px;
        padding-top: 10px;
        width: 98%;
        background: #f8f8f8;
        z-index: 4;
        margin-left: 5px;
        margin-right: 5px;
    }

    .snb_form_preview_actions {
        padding: 10px;
        margin: -15px -10px 30px -10px;

        h3 {
            display: inline-block;
            margin-bottom: 5px;
            width: auto;
        }

        a {
            cursor: pointer;
            background: #fff;
            color: #2979FF;
            width: 30px;
            height: 30px;
            text-align: center;
            float: right;
            line-height: 40px;
            margin: 0 0 0 10px;
            box-shadow: 0 0 20px rgba(145, 210, 220, 0.5);
            transition: all 0.3s ease-in-out;

            i {
                font-size: 18px;
            }
        }
    }

    h3 {
        color: #464646;
    }

    .data_bind {
        // margin-bottom: 5px; // padding: 0px 10px;
        // display: inline-block; // box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.09);
        // border-radius: 5px;
        // font-weight: 700;
        // line-height: 25px;
        // text-transform: capitalize;
        // color: #222222;

        .subfield_rt {
            margin-left: 80px;
        }

        span {
            line-height: 24px;
            font-weight: 700;
            line-height: 25px;
            color: #222222;
        }
    }

    .two-col {
        span.data_bind {
            margin-top: 6px;
        }
    }

    // p {
    //     line-height: 30px;
    //     text-align: justify;
    // }

    &.formxenabled {
        min-height: 55px;
        height: 55px;
        overflow: hidden;
        background: white;
        box-shadow: 0 0 0;
        position: relative;
        padding: 0 0 0;
        margin: 0 0 0 0;

        .formxactions {
            position: absolute;
            width: 100%;
            left: 0;
            padding: 0 20px;
            font-size: 24px;
            z-index: 100;
            background: #d2d4d4;

            .snb_form_preview_actions {
                margin: 0 0 0 0;
                padding: 0 0 0 0;
                background-color: transparent;
                line-height: 55px;
                color: #fff;
                font-weight: 700;

                a {
                    margin-top: 12px;
                }

                i {
                    font-size: 24px;
                }
            }
        }
    }
}

.snb_form_preview {
    // box-shadow: 0 0px 20px 0 rgba(145, 210, 220, 0.3);
    // padding: 10px;
    padding-top: 10px;
    margin-bottom: 30px;
    position: relative;

    h6 {
        margin-top: 15px;
    }

    .preview_left {
        position: relative;

        .company_img {
            width: 70px;
            height: 70px;
            display: inline-block;
            background: #edf2f7;
            border-radius: 80px;
            position: absolute;
            left: 0;
            top: 0;
            line-height: 95px;
            text-align: center;

            i {
                font-size: 40px;
                color: #a5a5a5;
            }
        }

        .form_Company_name {
            font-size: 16px;
            line-height: 50px;
            margin-left: 80px;
            padding: 0 0 20px 0;
            display: inline-block;
            font-weight: 700;
            max-width: 80%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            span {
                display: block;
                line-height: 0;
                font-weight: 400;
                color: #6a6a6a;
            }
        }
    }

    .preview_right {
        padding-top: 15px;

        .form_status_details {
            display: inline-block;
            background-color: $snb-terms-icon;
            padding: 0px 10px 0 0;
            color: #fff;
            font-size: 12px;
            font-weight: 700;
            line-height: 25px;
            border-radius: 5px;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
            overflow: hidden;

            span {
                background: rgba(0, 0, 0, 0.3);
                padding: 0 10px;
                line-height: 25px;
                display: inline-block;
            }
        }

        .form_chit_ref {
            margin: 5px 0;

            span {
                color: #a8a2a2;
                font-size: 12px;
            }
        }

        .chit_no {
            a {
                color: #0055b2;
                text-decoration: Underline;
                -webkit-transition: .2s;

                i {
                    color: #007aff;
                }

                &:hover {
                    color: hotPink;
                    text-decoration: underline;
                }
            }

            @media (-webkit-min-device-pixel-ratio:0) {
                a {
                    background-color: #0055b2;
                    background-image: -webkit-linear-gradient(left, hotPink 0%, orange 50%, transparent 50%);
                    background-position: 100% 0;
                    background-size: 200% 200%;
                    color: transparent;
                    -webkit-transition: .1s .2s;
                    -webkit-background-clip: text;
                }

                a:hover {
                    background-position: 0 0;
                    color: transparent;
                    transition: .4s 0;
                    text-decoration: underline;
                }
            }
        }
    }

    .form_attached_files {
        background-color: #f1f7f9;
        padding: 10px 15px;
        border-radius: 5px;

        a {
            display: block;
            color: #6e6e6e;
            font-size: 14px;
            line-height: 30px;
            margin-bottom: 5px;

            i {
                font-size: 18px;
                position: relative;
                top: 2px;
                color: #c7b299;
            }

            i.fa-eye {
                color: green;
            }

            .file-item {
                @media screen and (max-width: 768px) {
                    width: 85%;
                    font-size: 12px;
                }
            }
        }
    }
}

.form_right_block {
    .snb_form_preview {
        margin-right: -24px;

        @media screen and (max-width: 768px) {
            margin-right: 0;
        }

        &.preview_summery {
            .preview_step {
                padding: 5px 20px;

                .s-col {
                    background-color: #f7f7f7;
                    line-height: 40px;
                    padding: 0px 15px;
                    margin-bottom: 15px;
                    max-height: 40px;
                    width: 100%;
                    overflow: hidden;
                }

                .header_wrapper {
                    font-weight: 700;
                }

                .property {
                    width: 60%;
                    float: left;
                }

                .snb_stepper .s-col .value {
                    font-weight: 700;
                    width: 35%;
                    max-width: 35%;
                    float: left;
                    overflow: hidden;
                    height: 40px;
                }
            }
        }
    }

    &.full-screen-form {
        .snb_form_preview {
            margin-right: 0px !important;
        }
    }
}

// Upload section
.snb_upload_section {
    transition: all 0.15s ease-out;
    margin-bottom: 30px;

    snb-file-upload {
        app-snb-file-preview {
            i {
                padding-top: 3px !important;
            }
        }
    }

    .snb_upload_file {
        position: relative;

        .upload_icon {
            width: 50px;
            height: 50px;
            /* border: 1px solid red; */
            cursor: pointer;
            text-align: center;
            line-height: 50px;
            border-radius: 50px;
            box-shadow: 0 5px 30px 0 rgba(102, 148, 155, 0.2);
            display: inline-block;
            position: absolute;
            left: 0;
            top: 0;

            i {
                line-height: 50px;
            }

            input {
                opacity: 0;
                position: absolute;
                z-index: 4;
                top: 0;
                left: 0;
                cursor: pointer;
                height: 35px;
            }
        }

        .file_upload {
            margin-left: 65px;
            min-height: 70px;

            p {
                color: #9E9E9E;
                max-height: 32px;
                overflow: hidden;
                display: inline;
            }

            div.more_files_upload,
            div.file_name {
                margin-bottom: 0px;
                max-height: 50px;
                line-height: 1.2;
                font-size: 12px;
                width: 190px;
                display: inline-block;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                color: #bda7a7;
            }

            div.more_files_upload {
                line-height: 4;
            }

            h5 {
                padding-top: 10px;
                margin-bottom: 10px;
            }

            app-snb-file-preview {
                position: absolute;
                z-index: 100;
            }
        }
    }

    .sub_sec_actions {
        margin-bottom: 15px;

        button {
            border-radius: 30px;
            padding: 0px 20px 0px 3px;
            margin-right: 15px;
            overflow: hidden;
            box-shadow: 0 0px 5px 0 rgba(102, 148, 155, 0.4);

            .upload-file-input {
                z-index: 4;
                opacity: 0;
                position: absolute;
                left: 0;
                top: 0;
                line-height: 50px;
                cursor: pointer;
            }

            i {
                font-size: 30px;
                color: #1976d2;
            }
        }
    }

    .upload_wrapper {
        display: none;
        // Adding padding to match alignment of angular-5 padding which comes by default of 15px
        padding: 0px 15px;
    }

    background: #FEFEFC;
    max-height: 40px;
    transition: all 0.15s ease-out;

    &.expanded_section {
        max-height: none !important;
        transition: all 0.15s ease-out;
        overflow: visible;

        .upload_wrapper {
            display: block
        }
    }

    .sub_label {
        cursor: pointer;
    }
}

.snb_accordian_section {
    .sub_label {
        cursor: pointer;
    }

    .snb_accordian_content_section {
        display: none;
    }

    &.expanded_section {
        .snb_accordian_content_section {
            display: block;
        }
    }
}

.btn_form_preview_close {
    position: absolute !important;
    right: 45px !important;
    min-width: 30px !important;
    line-height: 20px !important;
    top: 70px !important;
    padding: 5px !important;
    z-index: 10 !important;
}

.devider {
    display: block;
    width: 100%;
    border-bottom: 1px solid#ccc;
    margin-bottom: 15px;
}

.single_comment {
    max-width: 100%;
    background: #fff;
    padding: 15px 20px;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
    margin: 15px;
    border-radius: 5px;

    .commented_by {
        color: #1976d2;
        font-weight: 700;
        line-height: 30px;
        display: inline-block;
    }

    .commented_time {
        color: #2196F3;
        font-weight: 500;
        line-height: 30px;
        float: right;
    }

    .commented_msg {
        background: #FAFAFA;
        padding: 15px 10px;
        border-radius: 3px;
    }

    .attachment {
        text-align: right;
    }
}

.snb-text-area {

    .material-icons {
        position: relative;
        top: 10px;
        border-right: 10px !important;
        background: #E3F2FD;
        border-radius: 55px;
        width: 15px !important;
        height: 15px;
        line-height: 15px !important;
        margin: 5px;
    }

    .icon-no-color-bg .material-icons{
        background: transparent;
        top: 0px;
        margin: 0px;
    }

    .mat-mdc-text-field-wrapper {
        min-height: fit-content;
    }

    .mat-mdc-form-field-flex {
        min-height: 100px !important;
    }

    .mat-mdc-form-field-icon-prefix {
        top: -30px;
    }

    .mat-mdc-form-field-infix .mdc-text-field__input{
        min-height: 82px;
    }
}

.cancel_application {
    display: flex;
    justify-content: center;
    padding: 20px;
}

// .type_outline_form_field
//   .mdc-text-field--filled:not(.mdc-text-field--disabled) {
//   background-color: transparent !important;
// }

// .type_outline_form_field .mat-mdc-form-field-icon-prefix {
//   padding: 0 4px 16px 0 !important;
// }

// .type_outline_form_field .mdc-text-field--outlined {
//   --mdc-outlined-text-field-outline-width: 0px !important;
//   --mdc-outlined-text-field-focus-outline-width: 0px !important;
//   --mdc-outlined-text-field-error-hover-outline-color: transparent !important;
// }

// .type_outline_form_field
//   .mat-mdc-form-field-has-icon-prefix
//   .mat-mdc-text-field-wrapper {
//   border: 2px solid #f0f3f4 !important;
// }

// .type_outline_form_field .mdc-floating-label--float-above {
//   background-color: #fff !important;
// }

// .type_outline_form_field.mat-mdc-form-field {
//   .mdc-text-field--focused,
//   .--mdc-outlined-text-field-error-outline-color {
//     border: 2px solid #f0f3f4;
//     --mdc-outlined-text-field-focus-outline-color: #f0f3f4 !important;
//   }
// }

// // .type_outline_form_field
// // {
// //     &.rs_form_entries .col-md-6 .mat-mdc-form-field {
// //         height: 40px !important;
// //     }
// // }

// .type_outline_form_field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field
//   .mdc-notched-outline__notch {
//   border-left: none !important;
// }

// .type_outline_form_field.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
//   .mdc-notched-outline
//   .mdc-notched-outline__notch {
//   border-width: 0px !important;
// }

// .type_outline_form_field.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused
//   .mdc-floating-label,
// .mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused
//   .mdc-floating-label--float-above {
// //   color: unset !important;
//    color:#282828 !important;
// }

// .mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled)
//   .mdc-floating-label,
// .mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled)
//   .mdc-floating-label--float-above {
//  // color: unset !important;
//  color: #282828 !important
// }

// .type_outline_form_field .mdc-floating-label {
//   transform: var(
//     --mat-mdc-form-field-label-transform,
//     translateY(-105%)
//       translateX(
//         calc(1 * (51px + var(--mat-mdc-form-field-label-offset-x, 0px)))
//       )
//   ) !important;
// }

// .type_outline_form_field
//   .mat-mdc-text-field-wrapper.mdc-text-field--outlined
//   .mat-mdc-form-field-infix {
//   padding-top: 8px !important;
// }

// .mdc-text-field--outlined .mdc-floating-label {
//   font-size: 14px !important;
// }

// .dialog-wrapper.type_outline_form_field .mdc-floating-label {
//   transform: var(
//     --mat-mdc-form-field-label-transform,
//     translateY(-115%)
//       translateX(calc(1 * (0% + var(--mat-mdc-form-field-label-offset-x, 0px))))
//   ) !important;
// }

// .type_outline_form_field .dialog-wrapper .snb-no-icon-label-adjust .mdc-floating-label {
//   transform: var(--mat-mdc-form-field-label-transform, translateY(-57%) translateX(calc(1 * (0px + var(--mat-mdc-form-field-label-offset-x, 0px))))) !important
// } 

// // .type_outline_form_field.mat-mdc-text-field-wrapper {
// //   display: flex !important;
// //   flex: none !important;
// //   align-items: center !important;
// //   height: 42px !important;
// // }

// .type_outline_form_field.mdc-text-field--no-label {
//   border: 2px solid #f0f3f4 !important;
// }

// .type_outline_form_field.type_outline_form_field {
//   .mdc-text-field--outlined {
//     border: 2px solid #f0f3f4 !important;
//     height: 40px !important;
//     border-bottom-left-radius: 5px !important;
//     border-bottom-right-radius: 5px !important;

//     &.mdc-text-field--disabled {
//       border: 1px solid #f0f3f4 !important;
//     }
//   }

//   .snb-text-area .mdc-text-field--outlined {
//     height: inherit !important ;
//   }
// }

// .type_outline_form_field .mat-mdc-form-field-error {
//   float: inline-end;
// }

// .type_outline_form_field .mat-form-field-disabled {
  
//   .mat-mdc-input-element, .mat-mdc-form-field-infix .mat-mdc-select {
//     &:hover {
//       cursor: not-allowed;
//     }
//   }
// }

// .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)::after {
//   color: #f44336 !important;
// }
// .snb-text-area .mdc-floating-label:not(.mdc-text-field) {
//     transform: var(--mat-mdc-form-field-label-transform, translateY(-57%) translateX(calc(1*(55px + var(--mat-mdc-form-field-label-offset-x, 0px))))) !important;
//   }
  
//   .mat-select-arrow {
//     display: table-cell;
//     vertical-align: middle;
// }
// .mat-mdc-select-trigger {
//     display: inline-table;
//     cursor: pointer;
//     position: relative;
//     box-sizing: border-box;
// }
// .mat-mdc-select-trigger {
//     height: 1.125em;
// }

// .mdc-text-field--outlined {
//     --mdc-outlined-text-field-container-shape: 0px !important;
// }
// .mat-mdc-form-field-has-icon-prefix .mat-mdc-text-field-wrapper {
//     --mat-mdc-form-field-label-offset-x: 0px !important; 
// }
// b, strong {
//     font-weight: bold !important;
// }

// .type_outline_form_field .snb_accordian_section.expanded_section .snb_accordian_content_section
// {
//     display:flex !important;
//     flex-wrap: wrap;
// }

.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__notch {
    max-width: calc(88% - max(12px, var(--mdc-outlined-text-field-container-shape))* 2) !important;
}

.mat-mdc-form-field-type-mat-select:not(.mat-form-field-disabled) .mat-mdc-text-field-wrapper, .mat-mdc-form-field-has-icon-prefix .mat-mdc-text-field-wrapper  {
    border-radius: 5px;
}

.material-icons.info{
    position: absolute;
    top: 17px;
    right: -34px;
    font-size: 16px;
}

// Apply color only when mat-form-field is enabled
.mat-mdc-form-field:not(.mat-form-field-disabled) .mat-mdc-form-field-icon-prefix > .mat-icon {
    color: #2962FF;
}

.mat-mdc-form-field {
    margin: 7px 0px;
    position: relative;
}

.mat-mdc-form-field:not(.demo-chip-list) .mat-mdc-text-field-wrapper,
.mat-mdc-form-field:not(.demo-chip-list).mat-mdc-form-field-has-icon-prefix .mat-mdc-text-field-wrapper,
.mat-mdc-form-field:not(.demo-chip-list).mat-mdc-form-field-type-mat-select:not(.mat-form-field-disabled) .mat-mdc-text-field-wrapper {
    max-height: 40px !important;
}

.mat-mdc-form-field:not(.demo-chip-list) .mat-mdc-form-field-flex {
    max-height: 40px !important;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix{
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

// matInput as attribute inside input field
.mat-mdc-form-field:not(.mat-form-field-disabled) .mat-mdc-floating-label.mdc-floating-label {
    transform: var(--mat-mdc-form-field-label-transform, translateY(-100%) translateX(calc(1*(51.97916793823242px + var(--mat-mdc-form-field-label-offset-x, 0px))))) !important;
}

// matSelect as input field
.mat-mdc-floating-label.mdc-floating-label {
    transform: var(--mat-mdc-form-field-label-transform, translateY(-100%) translateX(calc(1*(51.97916793823242px + var(--mat-mdc-form-field-label-offset-x, 0px))))) !important;
}

// order matters here
.snb-form-field-without-icon.mat-mdc-form-field
.mat-mdc-floating-label.mdc-floating-label {
    transform: var(--mat-mdc-form-field-label-transform, translateY(-100%) translateX(calc(1 * (0px + var(--mat-mdc-form-field-label-offset-x, 0px))))) !important;
}


.mat-mdc-form-field-error-wrapper,.mat-mdc-form-field-hint-wrapper {
    padding: 0px !important;
}

// remove red line inside the input element
.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field
  .mdc-notched-outline__notch {
  border-left: none !important;
}

// adjusting card inside mat-card
.mat-mdc-card-header {
    padding: 0px 16px !important;
}
.mat-mdc-card-content:last-child {
    padding-bottom: 0px !important; 
}
.mat-mdc-text-field-wrapper {
    display: flex !important;
    flex: none !important;
    align-items: center !important;
    height: 42px !important;
}

// Overriding checkbox color to blue instead of indigo-pink default theme 
.mat-mdc-checkbox-checked {
    --mdc-checkbox-selected-icon-color: #2979FF !important;
    --mdc-checkbox-selected-focus-state-layer-color: #2979FF !important;
    --mdc-checkbox-selected-hover-state-layer-color: #2979FF !important;
    --mdc-checkbox-selected-pressed-state-layer-color: #2979FF !important;
    --mdc-checkbox-selected-focus-icon-color: #2979FF !important;
    --mdc-checkbox-selected-hover-icon-color: #2979FF !important;
    --mdc-checkbox-selected-pressed-icon-color: #2979FF !important;
}
// Overriding radio button color to blue instead of indigo-pink default theme 
.mat-mdc-radio-button.mat-accent {
    --mdc-radio-selected-focus-icon-color: #2979FF !important;
    --mdc-radio-selected-hover-icon-color: #2979FF !important;
    --mdc-radio-selected-icon-color: #2979FF !important;
    --mdc-radio-selected-pressed-icon-color: #2979FF !important;
    --mat-radio-checked-ripple-color: #2979FF !important;
}

// Overriding check-box color for select-all to blue instead of indigo-pink default theme 
.mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background {
    border-color: #2979FF !important;
    background-color: #2979FF !important;
}

// Overriding border-radius of top,bottom,left,right from calculated to 0px
.mat-accordion .mat-expansion-panel:not(.mat-expanded), .mat-accordion .mat-expansion-panel:not(.mat-expansion-panel-spacing) {
    border-radius: 0px !important;
}

//Changing the font weight from bolder to bold
b, strong {
    font-weight: bold;
}

// Setting the line-height of the mat-hint below the form-field to avoid clash with below field
.mat-mdc-form-field-hint {
    line-height: 12px;
}
// Overriding the default font-size of subscript used for mat-hints
.mat-mdc-form-field-subscript-wrapper {
    font-size: 10px !important;
}
.mat-mdc-select-panel {
    min-width: 215px;
}

 .snb_material_transparent_card .mat-mdc-table .mat-mdc-cell {
    white-space: nowrap;
 }
// Removes padding on left-part of mat-form-field and provides more view of the input field
.no-left-padding {
    padding-left: 0px;
}

.snb_col .mat-mdc-form-field:not(.mat-form-field-disabled) .mat-mdc-floating-label.mdc-floating-label {
    max-width: 80%;
}
.snb_col .mat-mdc-form-field:not(.mat-form-field-disabled) .mat-mdc-floating-label.mdc-floating-label.mdc-floating-label--float-above {
    max-width: 150%;
}
.snb_min_col .mat-mdc-form-field:not(.mat-form-field-disabled) .mat-mdc-floating-label.mdc-floating-label.mdc-floating-label--float-above {
    max-width: 150%;
}
.snb_min_col .mat-mdc-form-field:not(.mat-form-field-disabled) .mat-mdc-floating-label.mdc-floating-label {
    max-width: 85%;
}
.data_bind {
    font-weight: bold;
}

.snb_form_preview_content .pso-number-block {
    border-style : dotted;
    color: green;
}