// @import 'conf/conf';
$left-space: $sidebar-width;

@mixin animation($animation...) {
    -moz-animation: $animation;
    -o-animation: $animation;
    -webkit-animation: $animation;
    animation: $animation;
}

input:-webkit-autofill {
    box-shadow: 0 0 0px 1000px white inset;
    -webkit-box-shadow: 0 0 0px 1000px white inset;
}

@include scrollbars(.5em, #d9d9d9, rgba(0, 0, 0, 0));

html {
    position: relative;
    min-width: 320px;
}

html,
body {
    min-height: 100%;
    height: 100%;
    min-width: $resMin;
}

html {
    top: 0px !important;
}

main {
    min-height: 100%;
    position: relative;
    font: 14px/16px $font-family;
    color: $default-text;
    background: #fff;
    @include body-bg();

    .additional-bg {
        display: none;
        @include additional-bg();
    }

    @keyframes pollanimation {
        0% {
            height: 0%;
        }

        100% {
            height: 100%;
        }
    }

    #logodes {
        overflow: hidden;
        position: relative;
        display: block;

        .lg-wrappper {
            background-color: #fff;
            border: 7px solid #B3B3B3;
            width: 44px;
            height: 44px;
            border-radius: 7px;
            vertical-align: bottom;
            position: relative;
            left: 0px;

            &:before {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                display: block;
                background-color: #fff;
                z-index: 0;
                bottom: -7px;
                right: -7px;
            }

            .darkpoll,
            .yellowpoll,
            .bluepoll {
                position: absolute;
                right: -7px;
                bottom: -7px;
                width: 7px;
                display: inline-block;
            }

            .animated {
                @for $i from 1 to 4 {
                    &:nth-child(#{$i}) {
                        @include animation (pollanimation 0.5s (0.1s * $i) infinite);
                    }
                }
            }

            .yellowpoll {
                max-height: 14px;
                background-color: #FAC310;
                right: 13px;
            }

            .bluepoll {
                max-height: 21px;
                background-color: #1D8CCD;
                right: 3px;
            }

            .darkpoll {
                max-height: 27px;
                background-color: #0A58A4;
            }
        }
    }

    .loading-wrapper #logodes {
        position: fixed;
        left: 0;
        width: 44px;
        height: 44px;
        right: 0;
        margin: auto;
        z-index: 999;
        top: 0px;
        bottom: 0;
    }
}

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
    html {
        overflow: hidden;
        height: 100%;
    }

    body {
        overflow: auto;
        height: 100%;
    }
}

a {
    transition: color 0.5s ease;
    outline: 0 !important;
}

.body-bg {
    display: none;
}

.al-header {
    display: block;
    height: 49px;
    margin: 0;
    background-repeat: repeat-x;
    position: relative;
    z-index: 905;
    color: #444444;
}

.snb-main-public {
    height: 100vh;
    overflow: auto;
    position: relative;
}

.snb-main {
    margin-left: $left-space;
    padding: 50px 0 0 0;
    position: relative;
    margin-right: 40px;
    overflow: hidden;
    overflow-y: scroll;
    height: 100vh;

    @media screen and (max-width: 768px) {
        margin-right: 0;
        height: auto;
    }
}

.al-footer {
    height: 34px !important;
    width: 100%;
    position: relative;
    display: block;
    bottom: 0;
    font-size: 13px;
    color: $default-text;
    transition: padding-left 0.5s ease;
}

.al-footer-main {
    float: left;
    margin-left: 15px;
}

.al-copy {
    float: left;
}

.al-footer-right {
    float: right;
    margin-right: 12px;

    i {
        margin: 0 4px;
        color: $danger;
        font-size: 12px;
    }

    a {
        margin-left: 4px;
        color: $default-text;

        &:hover {
            color: $danger;
        }
    }
}

.al-share {
    margin: -6px 0 0 12px;
    padding: 0;
    list-style: none;
    float: left;

    li {
        list-style: none;
        float: left;
        margin-left: 16px;

        i {
            cursor: pointer;
            transition: all 0.1s ease;
            color: white;
            padding: 6px;
            box-sizing: content-box;
            font-size: 16px;

            &:hover {
                transform: scale(1.2);
            }
        }

        i.fa-facebook-square {
            color: $facebook-color;
        }

        i.fa-twitter-square {
            color: $twitter-color;
        }

        i.fa-google-plus-square {
            color: $google-color;
        }
    }
}

.al-version {
    float: right;
    margin-left: 5px;

    @media screen and (max-width: 768px) {
        float: none;
        margin: 0;
    }
}

.snb-content {
    padding: 0px 24px 8px 24px;
    min-height: 95%;
    overflow-y: scroll;

    @media screen and (max-width: 768px) {
        padding: 0px 10px 8px 10px;
        min-height: 100%;
    }
}

.vis-hidden {
    visibility: hidden;
    position: absolute;
    top: -9999px;
    left: -9999px;
}

.icon-up,
.icon-down {
    width: 5px;
    height: 13px;
    display: block;
}

.icon-up {
    @include bg-nr('arrow-green-up.svg');
}

.icon-down {
    @include bg-nr('arrow-red-down.svg');
}

.disable-text-selection {
    -webkit-touch-callout: none;
    user-select: none;
}

.align-right {
    text-align: right
}

.amcharts-chart-div>a {
    font-size: 6px !important;
}

.content-panel {
    padding-left: 22px;
    padding-top: 26px;
}

@media (max-width: 590px) {
    .al-footer-right {
        float: none;
        margin-bottom: 19px;
        margin-right: 0;
    }

    .al-footer {
        height: 76px;
        text-align: center;
    }

    // .snb-main {
    //     padding-bottom: 76px;
    // }

    .al-footer-main {
        float: none;
        display: inline-block;
    }
}

.full-invisible {
    visibility: hidden !important;

    * {
        visibility: hidden !important;
    }
}

.irs-grid-text {
    color: $default-text;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.form-top-section {
    padding-left: 15px;
    padding-right: 15px;
}

.snb_content_section {
    padding: 24px;
    min-height: 450px;
    z-index: 0;

    .form_left_block,
    .form_right_block {
        -webkit-transition: 0.3s linear;
        transition: .3s linear;

        @media screen and (max-width: $resM) {
            padding: 24px;
        }
    }

    .full-screen-left {
        float: right;
    }

    .roc_need_more_info_text {
        font-weight: 600;
        font-size: 14px;
        padding-bottom: 19px;
        display: block;
        margin: 0px 0px 5px 15px;
    }

    ::ng-deep .mat-mdc-card {
        padding: -24px;
    }
    

    @media screen and (max-width: $resM) {
        margin-top: 20px;
        padding: 10px;
    }
}