@mixin svg-icon($url, $width:'', $height:'') {
  display: inline-block;
  background: url($url) no-repeat center;
  background-size: contain;
  vertical-align: middle;
  @if ($width != '') {
    width: $width + px;
  }
  @if ($height != '') {
    height: $height + px;
  }
}

@mixin svg-icon-class($iconName, $width:'', $height:'') {
  .#{'i-' + $iconName} {
    @include svg-icon($images-root + $iconName + '.svg', $width, $height);
  }
}

@include svg-icon-class('face', 80, 80);
@include svg-icon-class('money', 80, 80);
@include svg-icon-class('person', 80, 80);
@include svg-icon-class('refresh', 80, 80);

@mixin png-icon($url, $width, $height) {
  display: inline-block;
  width: $width + px;
  height: $height + px;
  background: url($url) no-repeat center center;
  background-size: $width + px $height + px;
}

@mixin png-icon-class($iconName, $width, $height) {
  .#{'i-' + $iconName} {
    @include png-icon($images-root + $iconName + '.png', $width, $height);
  }
}

//@include icon-png-class('arrival-icon', 11, 11);


// ngx datatable icons
@font-face {
  font-family: "data-table";
  src:url("/assets/fonts/data-table.eot");
  src:url("/assets/fonts/data-table.eot?#iefix") format("embedded-opentype"),
    url("/assets/fonts/data-table.woff") format("woff"),
    url("/assets/fonts/data-table.ttf") format("truetype"),
    url("/assets/fonts/data-table.svg#data-table") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "data-table" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
 
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="datatable-icon-"]:before,
[class*=" datatable-icon-"]:before {
  font-family: "data-table" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.datatable-icon-filter:before {
  content: "\62";
}
.datatable-icon-collapse:before {
  content: "\61";
}
.datatable-icon-expand:before {
  content: "\63";
}
.datatable-icon-close:before {
  content: "\64";
}
.datatable-icon-up:before {
  content: "\65";
}
.datatable-icon-down:before {
  content: "\66";
}
.datatable-icon-sort:before {
  content: "\67";
}
.datatable-icon-done:before {
  content: "\68";
}
.datatable-icon-done-all:before {
  content: "\69";
}
.datatable-icon-search:before {
  content: "\6a";
}
.datatable-icon-pin:before {
  content: "\6b";
}
.datatable-icon-add:before {
  content: "\6d";
}
.datatable-icon-left:before {
  content: "\6f";
}
.datatable-icon-right:before {
  content: "\70";
}
.datatable-icon-skip:before {
  content: "\71";
}
.datatable-icon-prev:before {
  content: "\72";
}
