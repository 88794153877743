.snb_card_list {
    .snb-workflow-table {
        .mat-mdc-row {
            position: relative;

            .mat-mdc-cell {
                line-height: 30px;

                .lint {
                    padding: 0px 10px;
                    border-radius: 3px;
                    font-size: 12px;
                    color: #fff;
                    font-weight: 500;
                    display: inline-block;
                    min-width: 70px;

                    &.form1a {
                        background-color: $snb-color-a;
                    }

                    &.form1b {
                        background-color: $snb-color-b;
                    }

                    &.form3 {
                        background-color: $snb-color-c;
                    }

                    &.formX {
                        background-color: $snb-color-d;
                    }

                    &.form5 {
                        background-color: $snb-color-e;
                    }

                    &.form7 {
                        background-color: $snb-color-f;
                    }

                    &.form13 {
                        background-color: $snb-color-g;
                    }

                    &.form5 {
                        background-color: $snb-color-h;
                    }

                    &.minutes {
                        background-color: $snb-color-i;
                    }

                    &.release {
                        background-color: $snb-color-j;
                    }
                }
            }

        }
    }

    .material {
        box-shadow: none !important;
        background-color: transparent !important;

        datatable-row-wrapper {
            margin: 10px 8px 10px 2px;
            background-color: #fff;
            padding: 7px 0px;
            border-radius: 5px;
            box-shadow: 0 1px 3px rgba(145, 210, 220, 0.12), 0 1px 2px rgba(145, 210, 220, 0.24);
            overflow: hidden;

            .datatable-row-odd {
                background-color: transparent !important;
            }

            datatable-body-row {
                position: relative;

                .datatable-body-cell {
                    line-height: 30px;

                    div.lint {
                        padding: 0px 10px;
                        border-radius: 3px;
                        font-size: 12px;
                        color: #fff;
                        font-weight: 500;
                        display: inline-block;
                        min-width: 70px;

                        &.form1a {
                            background-color: $snb-color-a;
                        }

                        &.form1b {
                            background-color: $snb-color-b;
                        }

                        &.form3 {
                            background-color: $snb-color-c;
                        }

                        &.formX {
                            background-color: $snb-color-d;
                        }

                        &.form5 {
                            background-color: $snb-color-e;
                        }

                        &.form7 {
                            background-color: $snb-color-f;
                        }

                        &.form13 {
                            background-color: $snb-color-g;
                        }

                        &.form5 {
                            background-color: $snb-color-h;
                        }

                        &.minutes {
                            background-color: $snb-color-i;
                        }

                        &.release {
                            background-color: $snb-color-j;
                        }
                    }
                }

                &:hover {
                    .datatable-row-group {
                        background-color: #fff !important;
                        cursor: pointer;
                    }
                }

                .table-col {
                    white-space: nowrap;
                    text-overflow: auto;
                    overflow: hidden;

                    &.divide {
                        .col-a {
                            font-size: 12px;
                            background-color: #81D4FA;
                            padding: 0px 5px;
                            font-weight: 700;
                            display: inline-block;
                            width: 60px;
                            text-align: center;
                        }

                        .col-b {
                            font-size: 12px;
                            background-color: rgb(240, 243, 244);
                            padding: 0px 5px;
                            font-weight: 700;
                            text-align: center;
                            display: inline-block;
                            width: 70px;
                        }
                    }
                }

                .company_title {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    padding-left: 40px;

                    i {
                        position: absolute;
                        color: rgba(0, 0, 0, 0.14);
                        left: 10px;
                        top: 8px;
                        background: #f6f6f6;
                        padding: 5px;
                        border-radius: 15px;
                        font-size: 22px;
                    }
                }
            }

            &:hover {
                box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
            }
        }

        .datatable-header {
            height: 40px !important;

            .datatable-header-cell {
                font-weight: 700;

                .datatable-header-cell-label {
                    font-size: 14px;
                }
            }

            .resize-handle {
                border-right: solid 1px #9E9E9E !important;
            }
        }

        .material-icons.actions {
            color: $snb-form-sub-link-color;
        }
    }

    .datatable-scroll {
        width: 100% !important;
    }
}

.mat-menu-panel {
    .chit-group-row-action {

        i.material-icons,
        i.fa {
            font-size: 18px;
            position: relative;
            top: 3px;
            color: $snb-form-sub-link-color;

            &:hover {
                color: $snb-form-sub-link-hvr-color;
            }
        }
    }
}

.snb_subheader_actions {
    position: absolute;
    top: 75px;
    z-index: 99;
    display: inline-flex;
    justify-content: flex-end;
    right: 60px;
    
    @media screen and (max-width: $resM) {
        right: 48px;
    }

    label i {
        background-color: #fff !important;
        border: 0px;
        font-size: 20px !important;
        text-align: center;
        border-radius: 3px;
        border: 0px;
        width: 36px;
        height: 36px;
        display: inline-block;
        line-height: 39px;
        overflow: hidden;
        -webkit-appearance: none !important;
        -moz-appearance: none !important;
        color: #2196f3 !important;
        box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
        cursor: pointer;
    }
}

#chitgroups {
    .material .datatable-header {
        height: 40px !important;
    }

    .company_code {
        font-weight: 700;
        font-size: 14px;
        text-transform: uppercase;
    }
}

.snb_filter {
    // padding: 0 15px;
    display: inline-block;
    // width: 70%;

    @media screen and (max-width: 768px) {
        width: 80%;
    }

    .mat-form-field {
        width: 100%;
        text-align: left;

        .mat-form-field-flex {
            // border: 1px solid #9E9E9E;
            background: #fff;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
            border-radius: 3px;
            min-height: 40px;
        }

        .mat-focused .mat-form-field-label-wrapper,
        .mat-form-field-underline {
            visibility: hidden;
        }

        .filter_icon {
            position: relative;
            top: 8px;
            padding: 0 15px;
            color: #2196F3;
        }

        .clear_btn {
            font-size: 20px;
            position: relative;
            top: 2px;
            margin-right: 5px;
        }
    }
}

.refresh_data {
    display: inline-block;
    width: 15%;

    button {
        min-width: 50px;
        background: #fff;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        border-radius: 3px;
        min-height: 40px;

        i {
            color: #2196F3;
            font-size: 17px;
        }
    }
}