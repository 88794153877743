.dialog-wrapper {
    background:  #fff;
    .dialog-title {
      background: #3f51b5;
      color: #fff;
      padding: 12px 15px;
      letter-spacing: 0.6px;

      .close-btn {
        float: right;
        cursor: pointer;
        color: #ffffff;
      }
    }

    .dialog-content-wrapper {
      padding: 15px;
      overflow: auto;

      .btn-group {
        margin-top: 15px;
        border-radius: 30px;
      }

      .text-wrapper {
        div {
          background: #f5f5f5;
          padding: 10px;
          border-radius: 5px;
          margin-bottom: 15px;

          span {
            font-weight: bold;
            float: right;
          }
        }
      }

      .static-data {
        margin-bottom: 15px;

        .inner-wrapper {
          background: #f7f7f7;
          padding: 23px 15px;
          border-radius: 5px;
          position: relative;

          span {
            font-weight: bold;
          }

          .status-wrapper {
            float: right;
            background: #fff;
            padding: 2px 0 2px 10px;
            border-radius: 30px;
            font-size: 12px;
            font-weight: normal;

            ._prop {
              font-weight: 600;
            }

            ._val {
              font-weight: 500;
              background: #666;
              border-radius: 30px;
              color: #fff;
              padding: 2px 10px;
              margin-left: 10px;
            }
          }

          .ribbon {
            position: absolute;
            top: 0;
            left: 0;
            font-size: 12px;
            background: #3f51b5;
            border-radius: 5px 0 8px 0;
            padding: 0px 15px;
            color: #fff;
            letter-spacing: 0.5px;
            font-weight: 500;
          }
        }
      }
      .cm-page-main-card {
        min-height: auto !important;
      }
    }

    .mat-stepper-header-container {
      display: none !important;
    }

    &.substitute-dialog-wrapper {
      .mat-stepper-header-container {
        display: flex !important;
        margin-bottom: 15px;
      }
    }

    .mat-horizontal-content-container {
      padding: 0 !important;
    }
  }


  .confirmation-dialog-wrapper {
    .confirmation-dialog-title {
      background: linear-gradient(45deg, #f66f2f 0%, #ea4c4c 100%);
      color: #fff;
      padding: 12px 15px;
      letter-spacing: 0.6px;

      i {
        font-size: 18px;
        position: relative;
        top: 3px;
      }

      .close-btn {
        float: right;
        cursor: pointer;
      }

      .title-ribbon-blue {
        background: linear-gradient(90deg, #0055b2, #007aff);
      }
    }

    .form_groups {
      padding: 15px;

      p {
        margin: 15px 0;
      }
    }

    .button-wrapper {
      div {
        width: 50%;
        float: left;
        background: #e7e7e7;
        text-align: center;
        border-top: 1px solid #ccc;
        cursor: pointer;

        button {
          background: none;
          border: none;
          width: 100%;
          height: auto;
          padding: 10px;
        }
      }

      .second {
        border-left: 1px solid #ccc;
      }
    }
  }

  .material_transparent_card {
    padding: 0px !important;
    clear: both;
    overflow: auto;
    background: transparent;
    box-shadow: none !important;

    mat-table {
      background: transparent;
      padding: 0 8px;

      mat-header-row {
        background: transparent;
        padding: 0px !important;
        min-height: 30px;

        mat-header-cell {
          color: #3f51b5;
          font-size: 13px;
          font-weight: bold;

          &:first-child {
            padding-left: 15px;
          }

          &:last-child {
            padding-right: 15px;
          }

          @media only screen and (max-width: 1280px) {
            font-size: 12px;
          }
        }
      }

      mat-row {
        background-color: #fff !important;
        // padding: 8px 10px;
        border-radius: 5px;
        margin: 10px 0;
        position: relative;
        box-shadow: 0 1px 3px rgba(63, 81, 181, 0.12), 0 1px 2px rgba(63, 81, 181, 0.24);
        transition: transform 0.3s;

        @media only screen and (max-width: 1280px) {
          min-height: 38px;
          margin: 5px 0;
        }

        &:hover {
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
          cursor: pointer;
          transform: scale(1.01);
        }

        .mat-mdc-cell {
          font-size: 13px;

          &:first-child {
            padding-left: 15px;
          }

          &:last-child {
            padding-right: 15px;
          }

          .ellipsis_text {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            padding-right: 10px;
          }

          @media only screen and (max-width: 1280px) {
            font-size: 12px;
          }
        }
      }

      .active_row {
        border-left: 2px solid #3f51b5;
      }

      .system_defined_role {
        background: #eceef8 !important;
      }

      .system_disabled {
        background: #e7e7e7 !important;

        .mat-mdc-cell {
          color: #808080 !important;
        }
      }

      .mat-mdc-cell {
        white-space: unset;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .trans-desc {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    mat-paginator {
      background: transparent;

      .mat-paginator-range-actions {
        .mat-paginator-range-label {
          color: #3f51b5;
          font-weight: bold;
          font-size: 13px;
        }

        button {
          color: #3f51b5 !important;

          svg {
            width: 20px !important;
          }
        }
      }
    }

    .empty-row {
      height: 50px;
      text-align: center;
      padding: 0.5rem 1.2rem;
      vertical-align: top;
      border-top: 0;
      padding-top: 15px !important;
    }

    .more-option-icon {
      font-size: 20px;
      padding: 5px;
      border-radius: 4px;
      background: rgba(63, 81, 181, 0.1);
      color: rgb(63, 81, 181);

      // &:hover {
      //   transition: 1s;
      //   transform: rotateY(180deg);
      //   border-radius: 50%;
      // }
      @media only screen and (max-width: 1280px) {
        font-size: 16px;
        padding: 4px;
      }
    }
  }

  .material_transparent_card_row_bg {
    mat-table {
      mat-row {
        background: #f7f7f7 !important;
      }
    }

    .more-option-icon {
      background: rgba(128, 128, 128, 0.2);
    }
  }

.collapse-table-card {
    mat-header-row {
      margin-bottom: 10px;
    }

    mat-row {
      margin: 5px 0 !important;

      .toggle-arrow {
        width: 20px;
        position: absolute;
        right: 70px;
        height: 20px;
        text-align: center;
        line-height: 30px;
        background: #eceef8;
        border-radius: 50%;

        mat-icon {
          font-size: 16px;
          width: 16px;
          color: #3f51b5;
          font-weight: 600;
        }
      }

      .info__icon {
        width: 20px;
        position: absolute;
        right: 11px;
        height: 20px;
        text-align: center;
        line-height: 30px;
        background: #e7e7e7;
        border-radius: 50%;

        i {
          position: relative;
          top: -6px;
          font-size: 8px;
          color: #808080;
        }
      }

      .comment__icon {
        position: absolute;
        background: #80808033;
        width: 20px;
        height: 20px;
        text-align: center;
        line-height: 20px;
        border-radius: 50%;
        i {
          font-size: 11px;
          color: #808080;
          text-shadow: 0 0 3px #ccc;
        }
      }

      .if__comment {
        span {
          width: 12px;
          height: 12px;
          background: #5cb85c;
          position: absolute;
          border-radius: 50%;
          right: -7px;
          top: -4px;
          font-size: 8px;
          color: #fff;
          line-height: 13px;
          text-align: center;
        }
      }

      .download__icon {
        width: 20px;
        position: absolute;
        right: 40px;
        height: 20px;
        text-align: center;
        line-height: 30px;
        background: #ffeee8;
        border-radius: 50%;

        i {
          position: relative;
          top: -6px;
          font-size: 9px;
          color: #fb5724;
        }
      }

      .delete__icon {
        width: 20px;
        position: absolute;
        right: 40px;
        height: 20px;
        text-align: center;
        line-height: 30px;
        background: #ffeee8;
        border-radius: 50%;

        i {
          position: relative;
          top: -6px;
          font-size: 11px;
          color: #fb5724;
        }
      }

      .eye__icon {
        width: 20px;
        position: absolute;
        right: 40px;
        height: 20px;
        text-align: center;
        line-height: 30px;
        background: #5cb85c33;
        border-radius: 50%;

        i {
          position: relative;
          top: -6px;
          font-size: 11px;
          color: #5cb85c;
        }
      }
    }

    .collapsed-row {
      background: #fff !important;
      position: relative;
      top: -5px;
      box-shadow: none;
      border-radius: 0 0 5px 5px;
      border-top: 1px dashed #b5beee;
      box-shadow: 0 1px 3px rgba(63, 81, 181, 0.12), 0 1px 2px rgba(63, 81, 181, 0.24);

      &:hover {
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        cursor: pointer;
        transform: scale(1);
      }

      .mat-mdc-cell {
        padding: 5px;

        .collapse-table-inner {
          width: 100%;
          padding: 5px 5px 5px 0;

          // mat-header-row {
          //   mat-header-cell {
          //     color: #808080 !important;
          //   }
          // }
          mat-row {
            min-height: 38px !important;
          }
        }
      }
    }

    .expanded {
      background: #eceef8 !important;
      border-radius: 5px 5px 0 0;

      &:hover {
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        cursor: pointer;
        transform: scale(1);
      }

      .mat-mdc-cell {
        color: #3f51b5 !important;
      }
    }
}

.element-row {
  position: relative;
}

.element-row:not(.expanded) {
  cursor: pointer;
}

.element-row:not(.expanded):hover {
  background: #f5f5f5;
}

.element-row.expanded {
  border-bottom-color: transparent;
}

.status-wrapper-box {
  color: #fff;
  padding: 1px 8px;
  border-radius: 3px;
  font-size: 12px;

  @media only screen and (max-width: 1280px) {
    padding: 0px 7px;
    font-size: 10px;
  }
}

.green-status {
  background: #5cb85c;
}

.red-status {
  background: #ef0a0a;
}

.reddishPink-status {
  background: #ff0b55;
}

.dark-red-status {
  background: #bd0909;
}

.grey-status {
  background: #808080;
}

.light-grey-status {
  background: #aaaaaa;
}

.yellow-status {
  background: #f7ce04;
}

.blue-status {
  background: #0e86e7;
}

.purple-status {
  background: #633193;
}

.orange-status {
  background: #f57b06;
}

.material-primary-status {
  background: #3f51b3;
}

.yellow-green-status {
  background: linear-gradient(147deg, #f7ce04 0%, #5cb85c 100%);
}

.orange-green-status {
  background: linear-gradient(147deg, #f57b06 0%, #5cb85c 100%);
}

.green-blue-status {
  background: linear-gradient(147deg, #5cb85c 0%, #0e86e7 100%);
}

span.highlight-orange {
  padding: 2px 8px;
  background: #f57b06;
  color: #ffffff;
  border-radius: 3px;
}

span.highlight-green {
  padding: 2px 8px;
  background: linear-gradient(147deg, #5cb85c 0%, #0e86e7 100%);
  color: #ffffff;
  border-radius: 3px;
}

.chit-water-mark {
      
  span {
    background: linear-gradient(147deg, #ff3837 0%, #ff8008 100%);
    position: relative;
    top: 0;
    font-size: 8px;
    padding: 0px 8px;
    height: 14px;
    line-height: 1.8;
    border-radius: 0 0 5px 5px;
    color: #fff;
    left: 10px;
  }
   
}

.data-wrapper {
  .strip {
      background: #e7e7e7;
      padding: 16px;
      border-radius: 5px;
      margin-bottom: 7px;

      ._value {
          width: 40%;
          float: left;
          margin-top: -8px;
      }

      ._property {
          width: 60%;
          float: left;
          margin-top: -8px;
      }
  }
}
