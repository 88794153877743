// main background
@mixin body-bg() {
    background-color: $body-bg;

 //$mainBgUrl: $images-root + 'sky-bg.jpg';

    &::before {
    content: '';
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
   //background: url($mainBgUrl) no-repeat center center;
    background-size: cover;
    will-change: transform;
    z-index: 0;
  }
}

// additional background if you want another layer
@mixin additional-bg() {
  //display: block; // additional background layer, hidden by default
}

::-webkit-scrollbar {
  width: 0;
}


$h1: 2.4rem;
$h2: 2.0rem;
$h3: 1.6rem;
$h4: 1.4rem;
$h5: 1.3rem;
$h6: 1.2rem;

// Toolbar
$toolbarHeight: 64px;

// Navigation Sidenav
$navigationWidth: 240px;
$navigationFoldedWidth: 64px;

$contrastsMap: (
    'dark': rgba(0, 0, 0, 0.87),
    'light': rgba(255, 255, 255, 0.87),
    'strong-light': rgb(255, 255, 255)
);

$material-colors: ( 
    'snb-theam': (

      // '50': #F9FBE7,
      //             '100': #F0F4C3,
      //             '200': #E6EE9C,
      //             '300': #DCE775,
      //             '400': #D4E157,
      //             '500': #CDDC39,
      //             '600': #C0CA33,
      //             '700': #AFB42B,
      //             '800': #9E9D24,
      //             '900': #827717,
      //             'A100': #F4FF81,
      //             'A200': #EEFF41,
      //             'A400': #C6FF00,
      //             'A700': #AEEA00,
            '50': #E3F2FD,
            '100': #BBDEFB,
            '200': #90CAF9,
            '300': #64B5F6,
            '400': #42A5F5,
            '500': #2196F3,
            '600': #1E88E5,
            '700': #1976D2,
            '800': #135ca8,
            '900': #063271,
            'A100': #82B1FF,
            'A200': #448AFF,
            'A400': #2979FF,
            'A700': #2962FF, 
            'full-f': #ffffff,
            'icon-c': #616161,
          )
); 



@function material-colors($color-name, $color-variant: '500') {
    $color: map-get(map-get($material-colors, $color-name),$color-variant);
    @if $color {
      @return $color;
    } @else {
      // Libsass still doesn't seem to support @error
      @warn "=> ERROR: COLOR NOT FOUND! <= | Your $color-name, $color-variant combination did not match any of the values in the $material-colors map.";
    }
};


  

 

// snb variables 
$snb-logo-border-color: material-colors('snb-theam', 'A200'); 
$snb-full-f :material-colors('snb-theam', 'full-f');;
$snb-sidebar-bg-color : material-colors('snb-theam', '900');
$snb-sidebar-icon-color : material-colors('snb-theam', '50');
$snb-sidebar-txt-color :  material-colors('snb-theam', '50');
$snb-sidebar-txt-hvr-color : material-colors('snb-theam', 'full-f');
$snb-sidebar-icon-hvr-color : material-colors('snb-theam', '600');
$snb-sidebar-active-link-color : material-colors('snb-theam', '800');


$snb-topbar-icon-color: material-colors('snb-theam', 'icon-c');
 

$snb-button-action: material-colors('snb-theam', '800'); 
$snb-hover-link: material-colors('snb-theam', '900');


$snb-form-title-icon-color: material-colors('snb-theam', '700');
$snb-form-sub-link-color: material-colors('snb-theam', 'A400');
$snb-form-sub-link-hvr-color: material-colors('snb-theam', '900');

$snb-input-icon-color:material-colors('snb-theam', 'A700');

// $snb-sidebar-sub-menu-bg-color :  material-colors('snb-theam', '900');

$snb-active-link: material-colors('snb-theam', '900');
$snb-terms-icon: material-colors('snb-theam', 'A400');


$snb-form-btn-submit: material-colors('snb-theam', '700');
$snb-form-btn-hover: material-colors('snb-theam', '900');
$snb-form-btn-cancel: material-colors('snb-theam', '900');

$snb-button-clicked: material-colors('snb-theam', '900');
$snb-button-hover: material-colors('snb-theam', '900');
$snb-label-line: material-colors('snb-theam', '900');

//Table colours

$snb-color-a: #4caf50;
$snb-color-b: #8bc34a;
$snb-color-c: #009688;
$snb-color-d: #2196f3;
$snb-color-e: #0673c9;
$snb-color-f: #ffc107;
$snb-color-g: #a40093;
$snb-color-h: #920d13;
$snb-color-i: #d67900;
$snb-color-j: #6b5354;




$resXXL: 1280px;
$resXL: 1170px;
$resL: 991px;
$resM: 768px;
$resS: 660px;
$resXS: 500px;
$resXXS: 435px;
$resMin: 320px;

$top-height: 50px;
$sidebar-width: 220px;  

$small-card-height: 114px;
$xsmall-card-height: 187px;
$medium-card-height: 400px;
$extra-medium-card-height: 550px;
$large-card-height: 974px;
 
$default-animation-duration: 0.2s;
$default-animation-style: ease-out;

$assets-root: '/assets/';
$images-root: $assets-root + 'img/';
$fonts-root: $assets-root + 'fonts/';
$font-thin: 100;
$font-light: 300;
$font-normal: 400;
$font-bold: 700;
$font-bolder: 500;
$font-ultraBold: 900;

$facebook-color: #3b5998;
$twitter-color: #55acee;
$google-color: #dd4b39;
$linkedin-color: #0177B5;
$github-color: #6b6b6b;
$stackoverflow-color: #2F96E8;
$dribble-color: #F26798;
$behace-color: #0093FA;


// Standard Shadows
$z1-shadow: 0  1px  3px rgba(#000, 0.12), 0  1px  2px rgba(#000, 0.24);
$z2-shadow: 0  3px  6px rgba(#000, 0.16), 0  3px  6px rgba(#000, 0.23);
$z3-shadow: 0 10px 20px rgba(#000, 0.19), 0  6px  6px rgba(#000, 0.23);
$z4-shadow: 0 14px 28px rgba(#000, 0.25), 0 10px 10px rgba(#000, 0.22);
$z5-shadow: 0 19px 38px rgba(#000, 0.30), 0 15px 12px rgba(#000, 0.22);

// Top shadows (for "bottom" positionned elements)
$top-z1-shadow: 0  -1px  3px rgba(#000, 0.12), 0  -1px  2px rgba(#000, 0.24);
$top-z2-shadow: 0  -3px  6px rgba(#000, 0.16), 0  -3px  6px rgba(#000, 0.23);
$top-z3-shadow: 0 -10px 20px rgba(#000, 0.19), 0  -6px  6px rgba(#000, 0.23);
$top-z4-shadow: 0 -14px 28px rgba(#000, 0.25), 0 -10px 10px rgba(#000, 0.22);
$top-z5-shadow: 0 -19px 38px rgba(#000, 0.30), 0 -15px 12px rgba(#000, 0.22);

// Shadows for full size "side" elements (using spread to make corners look right)
$side-z1-shadow: 0 0  3px 0.75px rgba(#000, 0.12), 0 0  2px 0.5px rgba(#000, 0.24);
$side-z2-shadow: 0 0  6px  1.5px rgba(#000, 0.16), 0 0  6px 1.5px rgba(#000, 0.23);
$side-z3-shadow: 0 0 20px    5px rgba(#000, 0.19), 0 0  6px 1.5px rgba(#000, 0.23);
$side-z4-shadow: 0 0 28px    7px rgba(#000, 0.25), 0 0 10px 2.5px rgba(#000, 0.22);
$side-z5-shadow: 0 0 38px  9.5px rgba(#000, 0.30), 0 0 12px   3px rgba(#000, 0.22);

// Raised button shadow (this in an approximation from  http://material-design.storage.googleapis.com/images/components-buttons-states-for-mocks-2a_large_mdpi.png)
$raised-button-normal-shadow:  1px 2px 3px rgba(#000, 0.17), 0 1px 4px rgba(#000, 0.16);
$raised-button-hover-shadow:   0   1px 3px rgba(#000, 0.13), 0 1px 4px rgba(#000, 0.16);
$raised-button-focused-shadow: 0   5px 6px rgba(#000, 0.15), 0 2px 5px rgba(#000, 0.16);
$raised-button-pressed-shadow: 0   6px 5px rgba(#000, 0.09), 0 7px 6px rgba(#000, 0.16);

$default: #ffffff; // default colors for layout
$body-bg: #F0F3F4; // default bg color

$default-text: #3f3f3f; // default text color layout (usually is the same as $default)
$content-text: #4d4d4d; // content text color on card, panels, form, etc
$help-text: $default-text; // additional text color for hits

$label-text: $default-text; // labels color
$disabled: rgba(255, 255, 255, 0.4); // color for disabled state
$disabled-bg: tint($disabled, 15%); // background color for disable state
$border: rgba(255, 255, 255, 0.5); // border color
$border-light: rgba(0, 0, 0, 0.3); // lighter version of border color (for hovers)
$input-border: rgba(255, 255, 255, 0.6); // input border color
$input-background: rgba(255, 255, 255, 0.1); // input border color for hover
$dropdown-text: #7d7d7d; // dropdown text color
 
// sidebar colors
$sidebar: #063271;
$sidebar-text: #ffffff; 

// specific components colors
$mail-box: whitesmoke;
$auth-panel-background: #ffffff;
$progress-background: rgba(0, 0, 0, 0.15);
$progress-default: rgba($default-text, 0.95);

// bootstrap card panel styles
$bootstrap-panel-radius: 2px;
$bootstrap-panel-text: #ffffff;
$bootstrap-panel-bg: rgba(255, 255, 255, 0.8);
$bootstrap-panel-header-bg: transparent;
$bootstrap-panel-header-border: 1px solid $border-light;
$bootstrap-panel-shadow: 0 5px 30px 0 rgba(102, 148, 155, 0.4);

// color scheme
$primary: #135ca8 !default;
$info: #1c8ccc !default;
$success: #1be500 !default;
$warning: #e7ba08 !default;
$danger: #f95372 !default;

$primary-light: tint($primary, 30%);
$info-light: tint($info, 30%);
$success-light: tint($success, 30%);
$warning-light: tint($warning, 30%);
$danger-light: tint($danger, 30%);

$primary-dark: shade($primary, 15%);
$info-dark: shade($info, 15%);
$success-dark: shade($success, 15%);
$warning-dark: shade($warning, 15%);
$danger-dark: shade($danger, 15%);

$primary-bg: tint($primary, 20%);
$info-bg: tint($info, 20%);
$success-bg: tint($success, 20%);
$warning-bg: tint($warning, 20%);
$danger-bg: tint($danger, 20%);

// link colors
$activelink: #285eb8;
$hoverlink: darken($activelink, 20%);



/*----------------------------------------------------------------*/
/*  Variables
/*----------------------------------------------------------------*/

// Typography
$font-family-body: 'Roboto', 'Helvetica Neue', 'Arial', sans-serif;
$font-family: 'Roboto', sans-serif;
$font-family-code: 'Monaco', 'Menlo', 'Consolas', 'Ubuntu Mono', monospace;

$font-size-root: 62.5%;
$font-size-base: 1.3rem;