.custom-data-table {
    .mat-mdc-header-cell {
        font-weight: 700;
        font-size: 14px;
        color: #424242;

        @media screen and (max-width: 768px) {
            font-size: 12px;
        }
    }

    .empty-row {
        height: 50px;
        text-align: center;
        padding: .5rem 1.2rem;
        vertical-align: top;
        border-top: 0;
        padding-top: 15px !important;
    }

    .active_row {
        background-color: #eeeeee;
    }

    .timeline_card {
        padding: 0 0 0 0;
        text-align: left;
    }

    .mat-mdc-row {
        cursor: pointer;
    }

    .right-col {
        min-height: 400px;
        margin-top: 30px;

        .step-col-label {
            font-weight: 700;
            margin-bottom: 10px;
            color: #2196F3;
            font-size: 14px;
        }
    }

    .step-col-descr {
        font-size: 11px;
    }

    .s-col {
        background-color: #f7f7f7;
        line-height: 40px;
        padding: 0px 15px;
        margin-bottom: 15px;
        box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
        
        @media screen and (max-width: 500px) {
            padding: 0px 3px;
            font-size: 12px;
        }

        div {
            display: inline-block;
        }

        .value {
            font-weight: 700;
            width: 60%;
            float: left;
            overflow: hidden;
            height: 40px;

            @media screen and (max-width: 768px) {
                width: 100%;
                font-weight: 300;
                font-size: 12px;
                line-height: 25px;
            }
        }

        .property {
            width: 40%;
            float: left;

            @media screen and (max-width: 768px) {
                width: 100%;
            }
        }

        width: 100%;
        overflow: hidden;
        max-height: none !important;
        white-space: normal !important;

        .desc {
            i {
                padding: 3px 10px;
                border-radius: 5px;
                background-color: #fff;
                box-shadow: 0 1px 10px rgba(0, 0, 0, 0.3);
                font-weight: 700;
                margin: 0 5px;
            }
        }
    }

    .s-col-full {
        max-height: none;
        white-space: normal;
        height: auto !important;
    }

    .s-col-ellipsis {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}