/* This file contains all the css related to deprecated owl date-time picker */
.cdk-global-overlay-wrapper,
.cdk-overlay-container {
	pointer-events: none;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%
}

.cdk-overlay-container {
	position: fixed;
	z-index: 1000
}

.cdk-overlay-container:empty {
	display: none
}

.cdk-global-overlay-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	position: absolute;
	z-index: 1000
}

.cdk-overlay-pane {
	position: absolute;
	pointer-events: auto;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	z-index: 1000;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	max-width: 100%;
	max-height: 100%
}

.cdk-overlay-backdrop {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1000;
	pointer-events: auto;
	-webkit-tap-highlight-color: transparent;
	-webkit-transition: opacity .4s cubic-bezier(.25, .8, .25, 1);
	-o-transition: opacity .4s cubic-bezier(.25, .8, .25, 1);
	-moz-transition: opacity .4s cubic-bezier(.25, .8, .25, 1);
	transition: opacity .4s cubic-bezier(.25, .8, .25, 1);
	opacity: 0
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
	opacity: 1
}

@media screen and (-ms-high-contrast:active) {
	.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
		opacity: .6
	}
}

.cdk-overlay-dark-backdrop {
	background: rgba(0, 0, 0, .288)
}

.cdk-overlay-transparent-backdrop,
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
	opacity: 0
}

.cdk-overlay-connected-position-bounding-box {
	position: absolute;
	z-index: 1000;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-moz-box-orient: vertical;
	-moz-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	min-width: 1px;
	min-height: 1px
}

.cdk-global-scrollblock {
	position: fixed;
	width: 100%;
	overflow-y: scroll
}

.owl-dialog-container {
	position: relative;
	pointer-events: auto;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	display: block;
	padding: 1.5em;
	-webkit-box-shadow: 0 11px 15px -7px rgba(0, 0, 0, .2), 0 24px 38px 3px rgba(0, 0, 0, .14), 0 9px 46px 8px rgba(0, 0, 0, .12);
	-moz-box-shadow: 0 11px 15px -7px rgba(0, 0, 0, .2), 0 24px 38px 3px rgba(0, 0, 0, .14), 0 9px 46px 8px rgba(0, 0, 0, .12);
	box-shadow: 0 11px 15px -7px rgba(0, 0, 0, .2), 0 24px 38px 3px rgba(0, 0, 0, .14), 0 9px 46px 8px rgba(0, 0, 0, .12);
	-moz-border-radius: 2px;
	border-radius: 2px;
	overflow: auto;
	background: #fff;
	color: rgba(0, 0, 0, .87);
	width: 100%;
	height: 100%;
	outline: 0
}

.owl-dt-container,
.owl-dt-container * {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box
}

.owl-dt-container {
	display: block;
	font-size: 12px !important;
	font-size: 1rem;
	background: #fff;
	pointer-events: auto;
	z-index: 1000
}

.owl-dt-container-row {
	border-bottom: 1px solid rgba(0, 0, 0, .12)
}

.owl-dt-container-row:last-child {
	border-bottom: none
}

.owl-dt-calendar {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-moz-box-orient: vertical;
	-moz-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	width: 100%
}

.owl-dt-calendar-control {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	font-size: 1em;
	width: 100%;
	padding: .5em;
	color: #000
}

.owl-dt-calendar-control .owl-dt-calendar-control-content {
	-webkit-box-flex: 1;
	-webkit-flex: 1 1 auto;
	-moz-box-flex: 1;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-moz-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-flex-align: center;
	align-items: center
}

.owl-dt-calendar-control .owl-dt-calendar-control-content .owl-dt-calendar-control-button {
	padding: 0 .8em
}

.owl-dt-calendar-control .owl-dt-calendar-control-content .owl-dt-calendar-control-button:hover {
	background-color: rgba(0, 0, 0, .12)
}

.owl-dt-calendar-main {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-moz-box-orient: vertical;
	-moz-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-flex: 1;
	-webkit-flex: 1 1 auto;
	-moz-box-flex: 1;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	padding: 0 .5em .5em;
	outline: 0
}

.owl-dt-calendar-view {
	display: block;
	-webkit-box-flex: 1;
	-webkit-flex: 1 1 auto;
	-moz-box-flex: 1;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto
}

.owl-dt-calendar-multi-year-view {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-flex-align: center;
	align-items: center
}

.owl-dt-calendar-multi-year-view .owl-dt-calendar-table {
	width: -webkit-calc(100% - 3em);
	width: -moz-calc(100% - 3em);
	width: calc(100% - 3em)
}

.owl-dt-calendar-multi-year-view .owl-dt-calendar-table .owl-dt-calendar-header th {
	padding-bottom: .25em
}

.owl-dt-calendar-table {
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0
}

.owl-dt-calendar-table .owl-dt-calendar-header {
	color: rgba(0, 0, 0, .4)
}

.owl-dt-calendar-table .owl-dt-calendar-header .owl-dt-weekdays th {
	font-size: .7em;
	font-weight: 400;
	text-align: center;
	padding-bottom: 1em
}

.owl-dt-calendar-table .owl-dt-calendar-header .owl-dt-calendar-table-divider {
	position: relative;
	height: 1px;
	padding-bottom: .5em
}

.owl-dt-calendar-table .owl-dt-calendar-header .owl-dt-calendar-table-divider:after {
	content: '';
	position: absolute;
	top: 0;
	left: -.5em;
	right: -.5em;
	height: 1px;
	background: rgba(0, 0, 0, .12)
}

.owl-dt-calendar-table .owl-dt-calendar-cell {
	position: relative;
	height: 0;
	line-height: 0;
	text-align: center;
	outline: 0;
	color: rgba(0, 0, 0, .85);
	-webkit-appearance: none;
	-webkit-tap-highlight-color: transparent;
	-webkit-tap-highlight-color: transparent
}

.owl-dt-calendar-table .owl-dt-calendar-cell-content {
	position: absolute;
	top: 5%;
	left: 5%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-moz-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	width: 90%;
	height: 90%;
	font-size: .8em;
	line-height: 1;
	border: 1px solid transparent;
	-moz-border-radius: 999px;
	border-radius: 999px;
	color: inherit;
	cursor: pointer
}

.owl-dt-calendar-table .owl-dt-calendar-cell-out {
	opacity: .2
}

.owl-dt-calendar-table .owl-dt-calendar-cell-today:not(.owl-dt-calendar-cell-selected) {
	border-color: rgba(0, 0, 0, .4)
}

.owl-dt-calendar-table .owl-dt-calendar-cell-selected {
	color: rgba(255, 255, 255, .85);
	background-color: #3f51b5
}

.owl-dt-calendar-table .owl-dt-calendar-cell-selected.owl-dt-calendar-cell-today {
	-webkit-box-shadow: inset 0 0 0 1px rgba(255, 255, 255, .85);
	-moz-box-shadow: inset 0 0 0 1px rgba(255, 255, 255, .85);
	box-shadow: inset 0 0 0 1px rgba(255, 255, 255, .85)
}

.owl-dt-calendar-table .owl-dt-calendar-cell-disabled {
	cursor: default
}

.owl-dt-calendar-table .owl-dt-calendar-cell-disabled>.owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected) {
	color: rgba(0, 0, 0, .4)
}

.owl-dt-calendar-table .owl-dt-calendar-cell-disabled>.owl-dt-calendar-cell-content.owl-dt-calendar-cell-selected {
	opacity: .4
}

.owl-dt-calendar-table .owl-dt-calendar-cell-disabled>.owl-dt-calendar-cell-today:not(.owl-dt-calendar-cell-selected) {
	border-color: rgba(0, 0, 0, .2)
}

.owl-dt-calendar-table .owl-dt-calendar-cell-active:focus>.owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected),
.owl-dt-calendar-table :not(.owl-dt-calendar-cell-disabled):hover>.owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected) {
	background-color: rgba(0, 0, 0, .04)
}

.owl-dt-calendar-table .owl-dt-calendar-cell-in-range {
	background: rgba(63, 81, 181, .2)
}

.owl-dt-calendar-table .owl-dt-calendar-cell-in-range.owl-dt-calendar-cell-range-from {
	-moz-border-radius-topleft: 999px;
	border-top-left-radius: 999px;
	-moz-border-radius-bottomleft: 999px;
	border-bottom-left-radius: 999px
}

.owl-dt-calendar-table .owl-dt-calendar-cell-in-range.owl-dt-calendar-cell-range-to {
	-moz-border-radius-topright: 999px;
	border-top-right-radius: 999px;
	-moz-border-radius-bottomright: 999px;
	border-bottom-right-radius: 999px
}

.owl-dt-timer {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-moz-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	width: 100%;
	height: 7em;
	padding: .5em;
	outline: 0
}

.owl-dt-timer-box {
	position: relative;
	display: -webkit-inline-box;
	display: -webkit-inline-flex;
	display: -moz-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-moz-box-orient: vertical;
	-moz-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	width: 25%;
	height: 100%
}

.owl-dt-timer-content {
	-webkit-box-flex: 1;
	-webkit-flex: 1 1 auto;
	-moz-box-flex: 1;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-moz-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	width: 100%;
	margin: .2em 0
}

.owl-dt-timer-content .owl-dt-timer-input {
	display: block;
	width: 2em;
	text-align: center;
	border: 1px solid rgba(0, 0, 0, .5);
	-moz-border-radius: 3px;
	border-radius: 3px;
	outline: medium none;
	font-size: 1.2em;
	padding: .2em
}

.owl-dt-timer-divider {
	display: inline-block;
	-webkit-align-self: flex-end;
	-ms-flex-item-align: end;
	align-self: flex-end;
	position: absolute;
	width: .6em;
	height: 100%;
	left: -.3em
}

.owl-dt-timer-divider:after,
.owl-dt-timer-divider:before {
	content: '';
	display: inline-block;
	width: .35em;
	height: .35em;
	position: absolute;
	left: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	-webkit-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
	background-color: currentColor
}

.owl-dt-timer-divider:before {
	top: 35%
}

.owl-dt-timer-divider:after {
	bottom: 35%
}

.owl-dt-control-button {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	cursor: pointer;
	outline: 0;
	border: none;
	-webkit-tap-highlight-color: transparent;
	display: inline-block;
	white-space: nowrap;
	text-decoration: none;
	vertical-align: baseline;
	margin: 0;
	padding: 0;
	background-color: transparent;
	font-size: 1em;
	color: inherit
}

.owl-dt-control-button .owl-dt-control-button-content {
	position: relative;
	display: -webkit-inline-box;
	display: -webkit-inline-flex;
	display: -moz-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-moz-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	outline: 0
}

.owl-dt-control-period-button .owl-dt-control-button-content {
	height: 1.5em;
	padding: 0 .5em;
	-moz-border-radius: 3px;
	border-radius: 3px;
	-webkit-transition: background-color .1s linear;
	-o-transition: background-color .1s linear;
	-moz-transition: background-color .1s linear;
	transition: background-color .1s linear
}

.owl-dt-control-period-button:hover>.owl-dt-control-button-content {
	background-color: rgba(0, 0, 0, .12)
}

.owl-dt-control-period-button .owl-dt-control-button-arrow {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-moz-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	width: 1em;
	height: 1em;
	margin: .1em;
	-webkit-transition: -webkit-transform .2s ease;
	transition: -webkit-transform .2s ease;
	-o-transition: transform .2s ease;
	-moz-transition: transform .2s ease, -moz-transform .2s ease;
	transition: transform .2s ease;
	transition: transform .2s ease, -webkit-transform .2s ease, -moz-transform .2s ease
}

.owl-dt-control-arrow-button .owl-dt-control-button-content {
	padding: 0;
	-moz-border-radius: 50%;
	border-radius: 50%;
	width: 1.5em;
	height: 1.5em
}

.owl-dt-control-arrow-button[disabled] {
	color: rgba(0, 0, 0, .4);
	cursor: default
}

.owl-dt-control-arrow-button svg {
	width: 50%;
	height: 50%;
	fill: currentColor
}

.owl-dt-inline-container,
.owl-dt-popup-container {
	position: relative;
	width: 18.5em;
	-webkit-box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);
	-moz-box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);
	box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12)
}

.owl-dt-inline-container .owl-dt-calendar,
.owl-dt-inline-container .owl-dt-timer,
.owl-dt-popup-container .owl-dt-calendar,
.owl-dt-popup-container .owl-dt-timer {
	width: 100%
}

.owl-dt-inline-container .owl-dt-calendar,
.owl-dt-popup-container .owl-dt-calendar {
	height: 20.25em
}

.owl-dt-dialog-container {
	max-height: 95vh;
	margin: -1.5em
}

.owl-dt-dialog-container .owl-dt-calendar {
	min-width: 250px;
	min-height: 330px;
	max-width: 750px;
	max-height: 750px
}

.owl-dt-dialog-container .owl-dt-timer {
	min-width: 250px;
	max-width: 750px
}

@media all and (orientation:landscape) {
	.owl-dt-dialog-container .owl-dt-calendar {
		width: 58vh;
		height: 62vh
	}

	.owl-dt-dialog-container .owl-dt-timer {
		width: 58vh
	}
}

@media all and (orientation:portrait) {
	.owl-dt-dialog-container .owl-dt-calendar {
		width: 80vw;
		height: 80vw
	}

	.owl-dt-dialog-container .owl-dt-timer {
		width: 80vw
	}
}

.owl-dt-container-buttons {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 2em;
	color: #3f51b5;
    height: 40px !important;
    font-size: 14px !important;
}

.owl-dt-container-control-button {
	font-size: 1em;
	width: 50%;
	height: 100%;
	-moz-border-radius: 0;
	border-radius: 0
}

.owl-dt-container-control-button .owl-dt-control-button-content {
	height: 100%;
	width: 100%;
	-webkit-transition: background-color .1s linear;
	-o-transition: background-color .1s linear;
	-moz-transition: background-color .1s linear;
	transition: background-color .1s linear
}

.owl-dt-container-control-button:hover .owl-dt-control-button-content {
	background-color: rgba(0, 0, 0, .1)
}

.owl-dt-container-info {
	padding: 0 .5em;
	cursor: pointer;
	-webkit-tap-highlight-color: transparent
}

.owl-dt-container-info .owl-dt-container-range {
	outline: 0
}

.owl-dt-container-info .owl-dt-container-range .owl-dt-container-range-content {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-moz-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding: .5em 0;
	font-size: .8em
}

.owl-dt-container-info .owl-dt-container-range:last-child {
	border-top: 1px solid rgba(0, 0, 0, .12)
}

.owl-dt-container-info .owl-dt-container-info-active {
	color: #3f51b5
}

.owl-dt-container-disabled,
.owl-dt-trigger-disabled {
	opacity: .35;
	filter: Alpha(Opacity=35);
	background-image: none;
	cursor: default !important
}

.owl-dt-timer-hour12 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-moz-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	color: #3f51b5
}

.owl-dt-timer-hour12 .owl-dt-timer-hour12-box {
	border: 1px solid currentColor;
	-moz-border-radius: 2px;
	border-radius: 2px;
	-webkit-transition: background .2s ease;
	-o-transition: background .2s ease;
	-moz-transition: background .2s ease;
	transition: background .2s ease
}

.owl-dt-timer-hour12 .owl-dt-timer-hour12-box .owl-dt-control-button-content {
	width: 100%;
	height: 100%;
	padding: .5em
}

.owl-dt-timer-hour12 .owl-dt-timer-hour12-box:focus .owl-dt-control-button-content,
.owl-dt-timer-hour12 .owl-dt-timer-hour12-box:hover .owl-dt-control-button-content {
	background: #3f51b5;
	color: #fff
}

.owl-dt-calendar-only-current-month .owl-dt-calendar-cell-out {
	visibility: hidden;
	cursor: default
}

.owl-dt-inline {
	display: inline-block
}

.owl-dt-control {
	outline: 0;
	cursor: pointer
}

.owl-dt-control .owl-dt-control-content {
	outline: 0
}

.owl-dt-control:focus>.owl-dt-control-content {
	background-color: rgba(0, 0, 0, .12)
}

.owl-dt-control:not(:-moz-focusring):focus>.owl-dt-control-content {
	-moz-box-shadow: none;
	box-shadow: none
}

.owl-hidden-accessible {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px
}