//@import "../../assets/css/print.css";
@import "../../assets/css/daterange.css";
@import "sass/conf/conf-a";
@import '~bootstrap/dist/css/bootstrap.css';
@import "sass/bootstrap-overrides/overrides";
// @import "~animate.css/animate";
@import "sass/typography";
@import "sass/buttons";
@import 'scss/partials/chitmonks';
@import 'scss/partials/data-table-row-details';
@import "sass/icons";
@import "sass/layout";
@import "sass/table";
@import "sass/form";
@import "sass/animate"; 
@import "../../../node_modules/@angular/material/prebuilt-themes/indigo-pink";
@import 'scss/partials/angular-material-custom';
@import 'scss/partials/animations';
@import 'scss/partials/colors';
@import '../../assets/css/date-time-picker.min.css';


// // Custom Theming for Angular Material
// // For more information: https://material.angular.io/guide/theming
// @use '@angular/material' as mat;
// // Plus imports for other components in your app.

// // Include the common styles for Angular Material. We include this here so that you only
// // have to load a single css file for Angular Material in your app.
// // Be sure that you only ever include this mixin once!
// @include mat.core();

// // Define the palettes for your theme using the Material Design palettes available in palette.scss
// // (imported above). For each palette, you can optionally specify a default, lighter, and darker
// // hue. Available color palettes: https://material.io/design/color/
// $theme-primary: mat.define-palette(mat.$indigo-palette);
// $theme-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// // The warn palette is optional (defaults to red).
// $theme-warn: mat.define-palette(mat.$red-palette);

// // Create the theme object. A theme consists of configurations for individual
// // theming systems such as "color" or "typography".
// $theme: mat.define-light-theme((
//   color: (
//     primary: $theme-primary,
//     accent: $theme-accent,
//     warn: $theme-warn,
//   ),
//   typography: mat.define-typography-config(),
// ));

// // Include theme styles for core and each component used in your app.
// // Alternatively, you can import and @include the theme mixins for each component
// // that you are using.
// @include mat.all-component-themes($theme);
