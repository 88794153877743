.cdk-overlay-container {
    z-index: 10000 !important;
}

.mat-select-panel {
    max-height: 300px !important;
}

.mat-select-value {
    white-space: nowrap !important;
    overflow: hidden !important;
}

.mdc-button--raised {
    min-width: 88px;
}

._button {
    .mat-ripple-element {
        background-color: $snb-form-sub-link-color;
        z-index: 0;
    }

    .mat-button-wrapper {
        z-index: 9;
        position: relative;
        ;
    }
}

.mat-mdc-tab-header, .mat-mdc-tab-nav-bar {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

//  when ever the tab is highlighted 
.mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
    color: black !important;
}

.mat-mdc-menu-panel.user-profile .mat-mdc-menu-content button span {
    font-size: 14px;
}

.mat-mdc-tab .mdc-tab-indicator__content--underline {
    border-top-width: 3px !important;
}

.label-success, .label-danger {
    color: #ffffff;
}

.label-success {
    background-color: #5cb85c;
}


.mat-mdc-snack-bar-container {

    div.mdc-snackbar__surface {
        background: transparent;
    }

    &.mdc-snackbar--open {
        position: relative;

        &:before {
            position: absolute;
            left: 0;
            top: 0;
            content: '';
            width: 4px;
            height: 100%;
            background-color: #4caf50 !important;
        }

        button {
            background: #4caf50 !important;
            color: #fff;
            border-radius: 3px;
        }

        &.snb-success-toaster .mat-mdc-snack-bar-label {
            background: #4caf50 !important;
            color: #ffffff;
        }

        &.snb-fail-toaster .mat-mdc-snack-bar-label {
            background: #EF5350 !important;
            color: #ffffff;

            &:before {
                background-color: #EF5350 !important;
            }

            button {
                background: #EF5350 !important;
                color: white !important;
            }
        }

        &.snb-info-toaster .mat-mdc-snack-bar-label {
            background: #2979FF !important;
            color: #ffffff;

            &:before {
                background-color: #2979FF !important;
            }

            button {
                background: #2979FF !important;
                color: white !important;
            }
        }
    }
}

.snb-success-toaster {
    --mat-snack-bar-button-color: #fff !important;
    .mdc-snackbar__surface {
        background-color: #4caf50 !important;
    }
}

.snb-fail-toaster .mdc-snackbar__surface{
    background-color: #EF5350 !important;
}

.mat-radio-button.mat-accent {
    .mat-radio-inner-circle {
        background-color: #0a58a4 !important;
    }

    .mat-radio-outer-circle {
        border-color: #0a58a4 !important;
    }
}

mat-row.mat-mdc-row, mat-header-row.mat-mdc-header-row {
    border-bottom: 1px solid  rgba(0, 0, 0, 0.12) !important;

    .mat-mdc-cell, .mat-mdc-header-cell {
        border-bottom: none !important;
        background: none;
    }
        
}
.mat-mdc-tab-label-container .mat-mdc-tab-list .mdc-tab {
    min-width: 160px;
}

.snb_stepper {
    &.light-header {
        .mat-stepper-header-container {
            background-color: #FAFAFA; // box-shadow: 0 1px 3px rgba(0,0,0,0.2);
        }
    }

    .header_wrapper {
        font-weight: 700;

        .property {
            width: 60%;
            float: left;
        }

        .value {
            width: 35%;
            max-width: 35%;
            float: left;
        }
    }
}

.minutes_wrapper {
    padding: 0 0 0 0 !important;

    .mat-horizontal-content-container {
        padding: 24px;
        min-height: 450px;
        color: #4d4d4d;
        background-color: #ffffff;
        border: 0;
        border-radius: 2px;
        position: relative;
        margin-bottom: 24px;
        box-shadow: 0 5px 30px 0 rgba(102, 148, 155, 0.4);
    }

    .snb_select_group {
        .mat-option {
            font-size: 14px !important;
        }
    }

    .mat-stepper-header .mat-step-icon,
    .mat-stepper-header .mat-step-icon-not-touched {
        font-size: 0 !important;
    }
}

.subscribers_present {
    .mat-header-row {
        background-color: #fafafa;

        .mat-mdc-header-cell {
            font-size: 14px;
            font-weight: 700;
        }
    }
}

.transfer_chit_wrapper {
    .mat-stepper-horizontal {
        background-color: #FAFAFA;
    }

    .mat-stepper-header-container {
        max-width: 400px;
    }
}

.complaint_wrapper {
    .mat-stepper-header-container {
        display: none;
    }
}

.snb_filter {
    .mat-form-field {

        &.mat-focused,
        &.mat-form-field-should-float {
            .mat-form-field-label-wrapper {
                display: none
            }
        }

        label.mat-form-field-label {
            left: 50px;
            line-height: 26px;
        }
    }

    .mat-form-field-infix {
        padding-top: 0;
        margin-top: 0;
        border-top: 0;
    }

    .mat-chip:not(.mat-basic-chip) {
        padding: 5px 12px;

        @media screen and (max-width: 768px) {
            margin: 0 0 5px 4px !important;
        }
    }
}

.mat-mdc-header-row,.mat-mdc-row {
    .mat-mdc-header-cell {
        font-weight: 700;
        font-size: 14px;
        color: #424242;
        padding: 0px;
    }
}

.mdc-data-table__cell ,.mdc-data-table__header-cell {
    padding: 0px !important;
}

.custom-data-table {
    .mat-mdc-header-row ,.mat-mdc-row{
        padding: 0 24px;

        .mat-mdc-header-cell {
            font-weight: 700;
            font-size: 14px;
            color: #424242;
        }
    }
}

.custome_table {

    .mat-header-row,
    .mat-row {
        @media screen and (max-width: 768px) {
            text-align: center;
            padding: 0 10px;
        }

        .mat-mdc-cell,
        .mat-mdc-header-cell {
            @media screen and (max-width: 768px) {
                font-size: 12px;
            }
        }
    }
}

.custome_strip {
    @media screen and (max-width: 500px) {
        .mat-stepper-header {
            display: block;
            overflow: none;
            padding: 0 5px;

            .mat-step-label {
                font-size: 10px;
            }

            .mat-stepper-horizontal-line {
                display: none !important;
            }

            .mat-step-icon,
            .mat-step-icon-not-touched {
                width: 20px;
                height: 20px;
                margin-left: 10px;
            }
        }
    }
}

.filter-dropdown-items {
    padding: 10px;
    min-width: 300px !important;
    margin-top: 25px;

    .mat-menu-content {
        padding: 0px !important;
        max-height: 220px;
    }

    .filter-items {
        cursor: pointer;
        padding: 10px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.12);
        font-size: 12px;
    }
}

.month-calendar-menu {
    padding: 10px;
    min-width: 300px !important;
    margin-top: 25px;
    height: 380px !important;

    .mat-menu-content {
        padding: 0px !important;
        max-height: 220px;
    }

    .month-calendar {
        cursor: pointer;
        padding: 10px;
        margin: 2px;
        width: 50px;
        text-align: center;
        display: inline-block;
        box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.12);

        &:hover {
            background: #fafbfd !important;
            box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
            border-radius: 3px;
            color: black;
        }
    }

    .selected-month {
        background: #135ca8 !important;
        color: white;
        box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
        border-radius: 3px;

        &:hover {
            background: #135ca8 !important;
            color: white;
        }
    }
}

.mat_header_cell {
    color: #003670;
    text-transform: capitalize;
}

.chitinfo {
    margin-top: 60px;

    mat-form-field {
        width: 100%;
    }

    @media screen and (max-width: 768px) {
        margin-top: 0;
    }
}

.help_sec {
    .mat-expansion-panel-header {
        padding: 26px 24px !important;

        @media screen and (max-width: 768px) {
            padding: 40px 24px !important;
        }
    }
}

.snb_material_transparent_card {
    padding: 0px !important;
    clear: both;
    overflow: auto;
    background: transparent;
    box-shadow: none !important;
    background-color: transparent !important;

    .mat-table, .mat-mdc-table {
        background: transparent;

        .mat-mdc-header-row {
            background: transparent;
            padding: 0px !important;
        }

        mat-row {
            background-color: #fff;
            padding: 8px 10px;
            border-radius: 5px;
            margin: 10px 0;
            position: relative;
            box-shadow: 0 1px 3px rgba(145, 210, 220, 0.12), 0 1px 2px rgba(145, 210, 220, 0.24);

            &:hover {
                box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
                cursor: pointer;
            }

            // .mat-mdc-cell {
            //     border: none;
            //     text-overflow: ellipsis;
            //     overflow: hidden;
            //     text-wrap: nowrap;
            // }
        }

        .active_row {
            border-radius: 0;
            border-left-width: 3px;
            border-left-style: solid;
            -webkit-border-image: -webkit-gradient(linear, 0 0, 0 100%, from(#0277bd), to(#04befe)) 1 100%;
            -webkit-border-image: -webkit-linear-gradient(#0277bd, #04befe) 1 100%;
            -moz-border-image: -moz-linear-gradient(#0277bd, #04befe) 1 100%;
            -o-border-image: -o-linear-gradient(#0277bd, #04befe) 1 100%;
            border-image: linear-gradient(to bottom, #0277bd, #04befe) 1 100%;
        }

        .mat-mdc-cell {
            // white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            .adjust_mat_table_cell_overflow {
                max-width: 140px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }

    mat-paginator {
        background: transparent;
    }
}

.show-errors-expansion-panel {
    .mat-expansion-panel-body {
        max-height: 300px;
        overflow: scroll;
    }
}

.service-request {
    .mat-stepper-header-container {
        display: none;
    }

    .mat-horizontal-content-container {
        margin-bottom: 0px;
    }
}

.form_field_2 {
    .title-ribbon {
        &:after {
            border-top: 28px solid #2979ff !important;
        }

        .mat-checkbox-layout {
            margin: 0;
        }

        .mat-checkbox-frame {
            border-color: #fff;
        }

        .mat-checkbox-label {
            display: none;
        }
    }
}

.ledger_attachment {
    .mat-mdc-cell {
        font-size: 12px;
    }
}

.example-tooltip-red {
    background: #b71c1c;
}

//******************************************************************
// Public chit-info mat-expansion-panel custom css 
.public-filter-data-fetched {
    .mat-expansion-panel-body {
        padding: 0px !important;
    }

    .mat-expansion-panel-spacing {
        margin: 0px !important;
    }
}

//******************************************************************
// my-organisation branches popup expansion panel styles starts here 
.branch_panel {
    @media screen and (max-width: 500px) {
        .mat-expansion-panel-body {
            padding: 0 10px 15px;
        }
    }
}

.mat-mdc-dialog-container .mdc-dialog__container { 
    .mdc-dialog__content {
        all: unset;
    }
}

// my-organisation branches popup expansion panel styles ends here 
//******************************************************************
//********************************************************************************************
// Foreman my-organisation branches -> Add Foreman -> popup custome style starts here
.custom-dialog-container .mat-mdc-dialog-container {
    padding: 0px !important;
    overflow: hidden !important;
}

//Ends here
//************************************************
//***************************************
//application component button style starts here
.application_btn {
    .mat-mdc-button {
        border-radius: 30px;
        box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
        min-width: 88px;
    }
}

//application component button style ends here
//***************************************
//***************************************
//filter option custome height for all filter starts here
.filter-option {
    line-height: 35px !important;
    height: 35px !important;

    .mat-option-text {
        span {
            font-size: 14px;
        }
    }
}

//filter option custome height for all filter ends here
//***************************************

//********************************************
// Highlight mat-mdc-cell if the data is wrong starts here
.showErrorForMatCell {
    color: red;
    font-weight: bold;
}

// Highlight mat-mdc-cell if the data is wrong ends here
//********************************************

//*************************************************
//Form-2 checkbox text wrap issue style Starts here
.form_2_checkbox {
    .mat-checkbox-layout {
        white-space: normal;
    }
}

//Form-2 checkbox text wrap issue style Ends here
//*************************************************


//*************************************************
//Report click down arrow style starts here
.cdk-column-rowClickArrow {
    width: 100px;
    flex: none !important;
    display: inline-block;
}

//Report click down arrow style Ends here
//*************************************************

.mat-datepicker-popup {
    z-index: 10000;
}

.payment-wrapper {
    position: fixed;
    bottom: 0;
    display: block;
    width: 350px;
    z-index: 2;
    right: 72px;
    // text-align: center;
    color: #fff;
    box-shadow: 0px -2px 14px rgba(0, 0, 00, 0.25);
    border-radius: 10px 10px 0 0;

    .left-box {
        padding: 15px 20px;
        float: left;
        width: 200px;
        background: #f0f3f4;
        color: #333;
        border-radius: 10px 0 0 0;
    }

    .right-box {
        padding: 15px 20px;
        float: left;
        width: calc(100% - (200px));
        background: linear-gradient(208deg, #0ab3e4, #102976);
        color: #fff;
        border-radius: 0 10px 0 0;
        cursor: pointer;
    }

    p {
        margin-bottom: 0;

        span {
            .fa-angle-double-right {
                animation: arrowLoop .75s 2s ease alternate infinite;
            }
        }

        @keyframes arrowLoop {
            0% {
                transform: translateX(0);
            }

            100% {
                transform: translateX(-7px);
            }
        }
    }

    @media screen and (max-width: 768px) {
        right: 0;
        width: 96%;
        z-index: 2;
        margin: 0 2%;
    }
}

.custom-element-mat-paginator {
    position: relative;
    top: 30px;

    div {
        padding: 10px 20px;
        display: inline;
        border-radius: 30px;
        cursor: pointer;
        background: linear-gradient(208deg, #0ab3e4, #102976);
        color: #fff;

        i {
            margin-left: 5px;
        }
    }

    @media screen and (max-width: 552px) {
        margin-top: 20px;
        top: 0;
        float: right;
    }
}

.form_attached_files a {
    color: #6e6e6e;
}

.form_attached_files a i.fa-eye {
    color: green;
}

.form_attached_files a i {
    font-size: 18px;
    position: relative;
    top: 2px;
}

.alert {
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
}

.alert-danger {
    background-color: #f2dede;
    border-color: #ebccd1;
    color: #a94442;
}

.alert-dismissible {
    padding-right: 35px;
}

@media (min-width: 992px) {
    .col-md-offset-3 {
        margin-left: 25%;
    }
}
//To remove horizontal scrollbar in dialog box
.mat-dialog-container, .chm-dialog-popup {
    overflow: hidden !important;
}
